import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import ImageTool from "@editorjs/image";
import List from "@editorjs/list";
import Paragraph from "@editorjs/paragraph";
import Table from "@editorjs/table";
import CodeTool from "@editorjs/code";
import Embed from "@editorjs/embed";
import Warning from "@editorjs/warning";
import LinkTool from "@editorjs/link";
import Raw from "@editorjs/raw";
import Quote from "@editorjs/quote";
import Marker from "@editorjs/marker";
import CheckList from "@editorjs/checklist";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import SimpleImage from "@editorjs/simple-image";
import NestedList from "@editorjs/nested-list";
import AlignmentTuneTool from "editorjs-text-alignment-blocktune";
import ColorPlugin from "editorjs-text-color-plugin";
import Fontsize from "editorjs-inline-font-size-tool";

export const Tools = {
  header: {
    class: Header,
    inlineToolbar: true,
    config: {
      placeholder: "Enter a heading",
      levels: [1, 2, 3, 4, 5, 6],
      defaultLevel: 5,
    },
    tunes: ["alignmentTuneTool"],
  },
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
    config: {
      placeholder: "Write paragraph",
    },
    tunes: ["alignmentTuneTool"],
  },
  list: {
    class: NestedList,
    inlineToolbar: true,
    config: {
      placeholder: "List",
      defaultStyle: "unordered",
    },
    shortcut: "CMD+SHIFT+L",
    tunes: ["alignmentTuneTool"],
  },
  image: {
    class: ImageTool,
    config: {
      endpoints: {
        // byFile: "http://localhost:8008/uploadFile", // Your backend file uploader endpoint
        // byUrl: "http://localhost:8008/fetchUrl", // Your endpoint that provides uploading by Url
      },
    },
  },
  table: {
    class: Table,
    inlineToolbar: true,
    config: {
      rows: 2,
      cols: 3,
    },
  },
  code: CodeTool,
  raw: Raw,
  embed: {
    class: Embed,
    inlineToolbar: true,
    config: {
      services: {
        youtube: true,
        soundcloud: true,
        twitter: true,
      },
    },
    shortcut: "CMD+SHIFT+E",
    tunes: ["alignmentTuneTool"],
  },
  warning: Warning,
  linkTool: LinkTool,
  quote: Quote,
  // Marker: {
  //   class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
  //   config: {
  //     type: "marker",
  //   },
  // },
  // Color: {
  //   class: ColorPlugin,
  //   config: {
  //     type: "text",
  //   },
  // },
  Color: {
    class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
    config: {
      defaultColor: "#FF1300",
      type: "text",
      customPicker: true, // add a button to allow selecting any colour
    },
  },
  Marker: {
    class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
    config: {
      defaultColor: "#FFBF00",
      type: "marker",
      customPicker: true,
    },
  },
  checklist: CheckList,
  delimiter: Delimiter,
  inlineCode: InlineCode,
  simpleImage: SimpleImage,
  fontsize: Fontsize,
  alignmentTuneTool: {
    class: AlignmentTuneTool,
    inlineToolbar: true,
  },
};
