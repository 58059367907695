import { useRef, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { bottom } from '@popperjs/core';
import { Link } from 'react-router-dom';
import Button from '../../form/Button';
import DeletePopup from '../../DeletePopup';

const TableActionAlt = ({
  xPlacement = 'right',
  dropdownData = [],
  itemId = null,
  setList = () => {}
}) => {
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null)
  const [deleteLink, setDeleteLink] = useState(null)
  const [deletePayload, setDeletePayload] = useState({})

  const manageDelete = (itemId, index) => {
    setShowDeleteModal(true)
    setDeleteId(itemId)

    if (dropdownData[index]) {
      setDeleteLink(dropdownData[index].deleteLink)
      setDeletePayload(dropdownData[index].deletePayload)
    }
  }

  const placements = {
    left: 'bottom-start',
    right: 'bottom-end',
    '': bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  return (
    <>
      <Menu as='div'>
        {({ open }) => (
          <>
            <Menu.Button ref={setTargetElement} className='flex items-center'>
              <div className='relative w-full h-10 flex items-center justify-center focus:ring-0 transition-all duration-200'>
                <div className='text-base text-slate-400 w-6 h-6 flex items-center justify-center'>
                  <i className='fa-regular fa-fw fa-ellipsis-vertical'></i>
                </div>
              </div>
            </Menu.Button>
            <Transition
              show={open}
              leave='transition duration-75'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
              className='z-50'
              beforeEnter={() => setPopperElement(popperElRef.current)}
              afterLeave={() => setPopperElement(null)}
              ref={popperElRef}
              style={styles.popper}
              {...attributes.popper}
            >
              <Menu.Items className='w-40 origin-top-right bg-white border-0 divide-y divide-slate-100 rounded-md shadow ring-0'>
                <div className='py-1 max-h-80 divide-y divide-slate-100 overflow-auto scroll-smooth scrollbar'>
                  {dropdownData.map((item, index) => (
                    <Menu.Item key={index} as='div'>
                      {item.type === 'link' ? (
                        <Link
                          to={item.link + `/${itemId}`}
                          className={
                            'flex items-center py-2 px-3 gap-3 font-medium transition-all duration-200 ' +
                            (item.standout
                              ? 'text-rose-500 hover:bg-rose-100'
                              : 'text-slate-600 hover:bg-slate-100')
                          }
                        >
                          <div className='w-5 h-5 flex items-center justify-center text-base'>
                            <i className={'fa-regular fa-fw ' + item.icon}></i>
                          </div>
                          <div className='text-xs'>{item.name}</div>
                        </Link>
                      ) : (
                        ''
                      )}
                      {item.type === 'button' ? (
                        <Button
                          buttonLabel={item.name}
                          buttonClasses={`!w-full !flex !items-center !justify-start !py-2 !px-3 !gap-3 !rounded-none !text-base !font-medium !bg-white ${
                            item.standout
                              ? '!text-rose-500 hover:!bg-rose-100'
                              : '!text-slate-600 hover:!bg-slate-100'
                          }`}
                          buttonType={'button'}
                          buttonIcon={'fa-regular ' + item.icon}
                          buttonIconPosition={'left'}
                          buttonHasLink={false}
                          buttonLabelClasses={'!text-xs !font-semibold'}
                          functions={() => {
                            if (item.actionType === 'delete') {
                              manageDelete(itemId, index)
                            }

                            if (item.onClick) {
                              item.onClick(itemId)
                            }
                          }}
                        />
                      ) : (
                        ''
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
      <DeletePopup 
        isOpen={showDeleteModal}
        setIsOpen={setShowDeleteModal}
        currentId={deleteId}
        setCurrentId={setDeleteId}
        deleteLink={deleteLink}
        setDeleteLink={setDeleteLink}
        deletePayload={deletePayload}
        setDeletePayload={setDeletePayload}
        deleteAction={setList}
      />
    </>
  );
};

export default TableActionAlt;
