import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../components/form/Button";
import ThreeDotsDropdown from "../components/form/ThreeDotsDropdown";

const ModalContainer = ({
  show = false,
  title = "",
  closeModal = () => {},
  modalSize = "",
  children,
  hasAction = false,
  actionData = [],
}) => {
  const [toggle, setToggle] = useState(false);
  const modalSizes = {
    "": "max-w-xs",
    xs: "max-w-xs",
    s: "max-w-sm",
    m: "max-w-md",
    l: "max-w-lg",
    xl: "max-w-xl",
    xxl: "max-w-2xl",
    xxxl: "max-w-3xl",
    xxxxl: "max-w-4xl",
    xxxxxl: "max-w-5xl",
    xxxxxxl: "max-w-6xl",
    xxxxxxxl: "max-w-7xl",
  };

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25"></div>
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`w-full ${modalSizes[modalSize]} transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all`}
              >
                {hasAction && (
                    <div className="absolute top-3 right-10 z-10">
                      <ThreeDotsDropdown dropdownData={actionData}/>
                    </div>
                  // <div className="w-fit absolute right-10 top-2 z-10">
                  //   <div
                  //     className="three-dot-btn cursor-pointer absolute right-0 h-8 w-8 flex items-center justify-center rounded-full"
                  //     onClick={() => setToggle(!toggle)}
                  //   >
                  //     <i class="fa-solid fa-ellipsis-vertical"></i>
                  //   </div>

                  //   {toggle ? (
                  //     <div className="dropdown-list  absolute top-6 right-3 shadow-md p-2 w-32 bg-white rounded-md">
                  //       <ul>
                  //         {actionData.map((item) => (
                  //           <li className="py-1 px-4 text-sm cursor-pointer hover:bg-gray-100 rounded-sm" key={item._id} onClick={item.onClick}>
                  //             {item.name}
                  //           </li>
                  //         ))}
                  //       </ul>
                  //     </div>
                  //   ) : null}
                  // </div>
                )}

                <Button
                  buttonClasses={
                    "!absolute !top-1 !right-1 !p-0 !w-10 !h-10 !bg-transparent !text-slate-400 !text-2xl hover:!text-rose-500"
                  }
                  buttonType={"button"}
                  buttonIcon={"fa-light fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonHasLink={false}
                  functions={closeModal}
                />

                <Dialog.Title
                  as="h3"
                  className="text-xl font-bold leading-6 text-gray-900 text-center mb-6"
                >
                  {title}
                </Dialog.Title>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalContainer;
