import { useState, useEffect, useRef } from "react";
import Input from "../form/Input";
import AddEditLayoutModal from "../../containers/modals/AddEditLayoutModal";
import { postData } from "../../services/api";
import toastr from "toastr";
import { Editor } from "@tinymce/tinymce-react";
import { tinymceApiKey } from "../../helpers";



const AddEditAppliances = ({
  title = "",
  show = false,
  editData = {},
  setEditData = () => {},
  setShow = () => {},
  setList = () => {},
  addType = "ASC",
}) => {
  const [disabled, setDisabled] = useState();
  const [titlename, setName] = useState("");
  const[description,setDescription]=useState('')
  const editorRef=useRef(null)

  useEffect(() => {
    if (editData?._id) {
      setName(editData.title);
      setDescription(editData.description)
      
    }
  }, [editData]);

  const closeModal = () => {
    setShow(false);
    setName("");
    setDescription("")
    setDisabled(false);
    setEditData({});
  };

  const onSubmit = async () => {
    setDisabled(true)
    const res = await postData(`appliance/${editData?._id ? 'edit'  : 'add'}`, { 

      title: titlename,
      description:description,
      id:  editData?._id || null,
      
    },editData._id?"PUT":"POST")

    if (res.status === 200 || res.status===201) {
      if (!editData?._id) {
        if (addType !== 'DESC') {
          setList(list => ({
            ...list,
            data: [ ...list.data, res.data ],
            count: list.count + 1
          }))
        } else {
          setList(list => ({
            ...list,
            data: [ res.data, ...list.data, ],
            count: list.count + 1
          }))
        }
      } else if (editData && editData?._id) {
        setList(list => ({
          ...list,
          data: list.data.map(item => item._id !== editData._id ? item : res.data)
        }))
      }

      closeModal()
      toastr.success(res.message)
    } else {
      setDisabled(false)
      toastr.error(res.message)
    }
  }

  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      setShow={setShow}
      onClose={closeModal}
      disabled={disabled}
      onSubmit={onSubmit}
      modalSize={"xl"}
    >
      <div className="relative w-full">
        <div className="mb-4">
        <Input
          label={" Appliance Title"}
          labelClasses={"!text-xs"}
          inputType={"text"}
          inputPlaceholder={"Enter Appliance Title"}
          inputValue={titlename}
          setInput={setName}
        />
        </div>
        <div className="mb-4">
        <div className="text-xs font-medium text-slate-500 mb-1 mt-3">Description</div>
        <Editor
          apiKey={tinymceApiKey()}
          onInit={(evt, editor) => (editorRef.current = editor)}
          value={description}
          init={{
            height: 300,
            menubar: false,
            plugins: [
              "code",
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
            ],
            toolbar:
              "code | undo redo | formatselect | " +
              "bold italic backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help ",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
          onEditorChange={(newText) => setDescription(newText)}
        />
        </div>

      </div>
    </AddEditLayoutModal>
  );
};

export default AddEditAppliances;
