import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// import Table from '../components/elements/table/Table';
import TableAlt from '../components/elements/table/TableAlt';
import ListLayout from '../containers/ListLayout';
import { postData } from '../services/api';
// import { convertTimezone, formatDate } from '../helpers';
import toastr from 'toastr';

import AddEditSubscriptionModal from '../components/modals/AddEditSubscriptionModal';

const Subscriptions = () => {
  const user = useSelector((state) => state.auth.user);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0)
  const [keyword, setKeyword] = useState('');
  const [list, setList] = useState({
    loading: true,
    data: [],
    count: 0,
  });
  const [sortField, setSortField] = useState(null)
  const [sortType, setSortType] = useState(null)

  const [showModal, setShowModal] = useState(false)
  const [editData, setEditData] = useState({})

  useEffect(() => {
  
      const loadList = async () => {
        setList((list) => ({ ...list, loading: true }));
        const searchParams = {}
        if (keyword) {
          searchParams.name = { $regex: keyword, $options: 'i' }
        }
        const res = await postData('subscription/list', {
          keyword,
          keyword_key: 'name',
          limit: limit,
          offset: offset
        });
        if (res.status === 200) {
          setList((list) => ({
            ...list,
            data: res.data.list,
            loading: false,
            count: res.data.count,
          }));
        } else {
          setList((list) => ({ ...list, loading: false }));
        }
      };

      loadList();
  }, [user, keyword, limit, offset, sortField, sortType]);


//   const onDrag = async (data = []) => {
//     const res = await postData('crud/ordering', {
//       table_name:'category',
//       ordering_data: data.map((item, i) => ({ id: item._id, ordering: i }))
//     })

//     if (res.status !== 200) {
//       toastr.error(res.message)
//     }   
//   }


  const tableHeadData = [
    {
      _id: 1,
      width: 15,
      title: 'Name',
      name: 'name',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 2,
      width: 15,
      title: 'Email',
      name: 'email',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
        _id: 3,
        width: 15,
        title: 'Phone',
        name: 'phone',
        align: 'left',
        sortable: true,
        filterable: false,
      },
      {
        _id: 4,
        width: 15,
        title: 'Payment Status',
        name: 'status',
        align: 'left',
        sortable: true,
        filterable: false,
      },
    {
      _id: 5,
      width: 15,
      title: 'Created At',
      name: 'created_at',
      align: 'left',
      sortable: true,
      filterable: false,
    }
  ];





  const actionData = (item) => [
    {
      name: 'Edit',
      icon: 'fa-pen-to-square',
      type: 'button',
      onClick: id => {
        setShowModal(true)
        setEditData(list.data.find(item => item._id === id) || {})
      }
    },
    {
      name: item.status ? 'Inactive' : 'Active',
      icon: item.status ? 'fa-circle-exclamation' : 'fa-circle-check',
      type: 'button',
      onClick:""
    },
    {
      name: 'Delete',
      icon: 'fa-trash-can',
      type: 'button',
      actionType: 'delete',
      deleteLink: '',
      deletePayload: { id: item._id },
      standout:true
    }
  ]

  return (
    <>
      <ListLayout
        title='Subscriptions'
        addName=''
        onAdd={() => false}
        limit={limit}
        setLimit={setLimit}
        keyword={keyword}
        setKeyword={setKeyword}
        noOfPage={Math.ceil(list.count / limit)}
        totalCount={list.count}
        onPageChange={(val) => setOffset(val.selected * limit)}
        disableButton={true}
      >
        <TableAlt
          tableHeadData={tableHeadData}
          tableData={list.data}
          loading={list.loading}
          sortField={sortField}
          sortType={sortType}
          setSortField={setSortField}
          setSortType={setSortType}
          actionData={actionData}
          setList={setList}
          hasActions={false}
          
        //   isDraggable={true}
        //   onDrag={onDrag}
        />
      </ListLayout>
      <AddEditSubscriptionModal 
        show={showModal}
        setShow={setShowModal}
        title={Object.keys(editData).length > 0 ? 'Edit Category' : 'Add Category'}
        setList={setList}
        editData={editData}
        setEditData={setEditData}
      />
    </>
  );
};

export default Subscriptions;
