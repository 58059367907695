import { useState, useEffect, useRef } from "react";
import AddEditLayoutModal from "../../containers/modals/AddEditLayoutModal";
import Input from "../form/Input";
import Image from "../elements/Image";
import ButtonFile from "../form/ButtonFile";
import { fileUpload, postData } from "../../services/api";
import { tinymceApiKey } from "../../helpers";
import toastr from "toastr";
import Select from "../form/Select";
import DropdownCheckbox from "../form/DropdownCheckbox";
import { Editor } from "@tinymce/tinymce-react";
import Button from "../form/Button";

const AddEditDefficultsModal = ({
  title = "",
  show = false,
  editData = {},
  setEditData = () => {},
  setShow = () => {},
  list = [],
  setList = () => {},
  addType = "ASC",
}) => {
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData({
      name: editData?.name,
    });
  }, [editData?._id]);

  // close modal ============>

  const closeModal = () => {
    setShow(false);
    setLoader(false);
    setFormData({});
  };

  //   on input change =========>

  const onFieldChange = (_, e) => {
    const { name, value } = e.target;
    setFormData((preState) => ({ ...preState, [name]: value }));
  };

  //   form submit ==========>

  const onSubmit = async () => {
    setLoader(true);
    const res = await postData(
      `difficulties${editData?._id ? "/" + editData?._id : ""}`,
      {
        ...formData,
        id: editData?._id || null,
        ordering: editData?._id ? editData.ordering : list.length,
      },
      editData?._id ? "PUT" : "POST"
    );

    if (res?.status === 200 || res?.status === 201) {
      if (!editData?._id) {
        setList((preState) => ({
          ...preState,
          data: [res?.data, ...preState?.data],
        }));
      }
      if (editData?._id) {
        setList((preState) => ({
          ...preState,
          data: preState?.data?.map((item) =>
            item?._id === res?.data?._id ? res.data : item
          ),
        }));
      }
      toastr.success(res.message);
      closeModal();
    } else {
      toastr.error(res.message);
    }
  };

  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      modalSize="m"
      setShow={setShow}
      onClose={closeModal}
      disabled={loader}
      onSubmit={onSubmit}
    >
      <div className="relative w-full flex gap-4">
        <div className="w-full">
          <div className="flex items-end gap-4">
            <div className="mb-4 w-full">
              <Input
                label={"Meal Times"}
                inputName={"name"}
                labelClasses={"!text-xs"}
                inputType={"text"}
                inputPlaceholder={"Enter meal times name"}
                inputValue={formData?.name}
                setInput={onFieldChange}
              />
            </div>
          </div>
        </div>
      </div>
    </AddEditLayoutModal>
  );
};

export default AddEditDefficultsModal;
