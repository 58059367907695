import { useState, useEffect, useRef } from "react";
import AddEditLayoutModal from "../../containers/modals/AddEditLayoutModal";
import Input from "../form/Input";
import Image from "../elements/Image";
import ButtonFile from "../form/ButtonFile";
import { fileUpload, postData } from "../../services/api";
import { getQueriesParams, tinymceApiKey } from "../../helpers";
import toastr from "toastr";
import Select from "../form/Select";
import DropdownCheckbox from "../form/DropdownCheckbox";
import Button from "../form/Button";
import TextEditor from "../elements/texteditor/TextEditor";

const AddEditRecipeModal = ({
  title = "",
  show = false,
  editData = {},
  setEditData = () => {},
  setShow = () => {},
  list = [],
  setList = () => {},
  addType = "ASC",
}) => {
  const [loader, setLoader] = useState(false);
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const descriptionRef = useRef(null);

  // new state ============>

  const [mealTimeList, setMealTimeList] = useState({
    loading: false,
    data: [],
  });
  const [themeList, setThemeList] = useState({
    loading: false,
    data: [],
  });
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const loadMealData = async () => {
      let query = getQueriesParams({
        limit: 10,
        offset: 0,
      });
      setMealTimeList((list) => ({ ...list, loading: true }));
      const res = await postData(`meal-types/${query}`, {}, "GET");
      if (res.status === 200) {
        setMealTimeList((list) => ({
          ...list,
          data: res?.docs,
          loading: false,
        }));
      } else {
        setMealTimeList((list) => ({ ...list, loading: false }));
      }
    };
    const loadThemeData = async () => {
      let query = getQueriesParams({
        limit: 10,
        offset: 0,
      });
      setThemeList((list) => ({ ...list, loading: true }));
      const res = await postData(`categories/${query}`, {}, "GET");
      if (res.status === 200) {
        setThemeList((list) => ({
          ...list,
          data: res?.docs,
          loading: false,
        }));
      } else {
        setThemeList((list) => ({ ...list, loading: false }));
      }
    };

    if (show) {
      loadMealData();
      loadThemeData();
    }
  }, [show]);

  const closeModal = () => {
    setShow(false);
  };

  const onImageChange = async (e) => {
    setLoader(true);
    if (e.target.files.length) {
      setImage(URL.createObjectURL(e.target.files[0]));
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const imgRes = await fileUpload("file-upload", formData, "POST");

      if (imgRes.status === 200) {
        setImage(imgRes?.location);
        setLoader(false);
      } else {
        setLoader(false);
      }
    }
  };

  const onSubmit = async () => {};

  const onchangeHandler = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setFormData((preState) => ({ ...preState, [name]: value }));
  };

  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      modalSize="xxxxxl"
      setShow={setShow}
      onClose={closeModal}
      disabled={loader}
      onSubmit={onSubmit}
    >
      <div className="relative w-full flex gap-4">
        <div className="w-2/3 border-r border-gray-100 pr-4">
          <div className="flex items-end gap-4">
            <div className="mb-4 w-1/2">
              <Input
                label={"Recipe Name"}
                inputName={"name"}
                labelClasses={"!text-xs"}
                inputType={"text"}
                inputPlaceholder={"Enter Recipe Name"}
                inputValue={formData.name}
                setInput={(_, e) => onchangeHandler(e)}
              />
            </div>
            <div className="mb-4 w-1/2">
              <Select
                labelClasses={"!text-xs"}
                xPlacement={"left"}
                dropdownButtonClass={"!bg-white"}
                label={"Select mealtime"}
                selectedValue={""}
                dropdownData={[
                  { _id: 0, label: "Select mealtime", value: 0 },
                  ...mealTimeList?.data?.map((item) => ({
                    _id: item?._id,
                    label: item?.name,
                    value: item?._id,
                  })),
                ]}
                getSelectedValue={(e) =>
                  onchangeHandler({ target: { ...e, name: "mealTime" } })
                }
              />
            </div>
          </div>

          <div className="flex items-end gap-4">
            <div className="mb-4 w-1/2">
              <Select
                labelClasses={"!text-xs"}
                xPlacement={"left"}
                dropdownButtonClass={"!bg-white"}
                selectedValue={""}
                label={"Select theme"}
                dropdownData={[
                  { _id: 0, label: "Select mealtime", value: 0 },
                  ...themeList?.data?.map((item) => ({
                    _id: item?._id,
                    label: item?.name,
                    value: item?._id,
                  })),
                ]}
                getSelectedValue={(e) =>
                  onchangeHandler({ target: { ...e, name: "theme" } })
                }
              />
            </div>
            <div className="mb-4 w-1/2">
              <Input
                label={"Calories per meal"}
                labelClasses={"!text-xs"}
                inputType={"text"}
                inputName={"calories"}
                inputPlaceholder={"Enter calories quentity"}
                inputValue={formData.calories}
                setInput={(_, e) => onchangeHandler(e)}
              />
            </div>
          </div>

          <div className="flex items-end gap-4">
            <div className="mb-4 w-1/2">
              <Select
                labelClasses={"!text-xs"}
                xPlacement={"left"}
                dropdownButtonClass={"!bg-white"}
                selectedValue={""}
                dropdownData={[{ _id: 0, label: "Select recipe", value: 0 }]}
                label={"Select recipe"}
                getSelectedValue={(e) =>
                  onchangeHandler({ target: { ...e, name: "recipe" } })
                }
              />
            </div>
            <div className="mb-4 w-1/2">
              <Select
                labelClasses={"!text-xs"}
                xPlacement={"left"}
                dropdownButtonClass={"!bg-white"}
                selectedValue={""}
                dropdownData={[
                  { _id: 0, label: "Select difficulty", value: 0 },
                ]}
                label={"Select difficulty"}
                getSelectedValue={(e) =>
                  onchangeHandler({ target: { ...e, name: "difficulty" } })
                }
              />
            </div>
          </div>

          <div className="text-sm font-medium text-slate-500 mb-1 mt-3">
            Description
          </div>
        </div>

        <div className="w-1/3">
          {image !== null && (
            <div className="relative w-full rounded border border-slate-200 flex overflow-hidden mb-4 mt-5">
              <Button
                buttonClasses={
                  "!absolute !z-10 !top-1 !right-1 !cursor-pointer !bg-white !text-slate-400 !w-6 !h-6 !rounded-full !shadow !text-sm"
                }
                buttonType={"button"}
                buttonIcon={"fa-light fa-xmark"}
                buttonIconPosition={"left"}
                buttonHasLink={false}
                functions={() => setImage(null)}
              />
              <Image
                src={image}
                alt={image}
                width={"100%"}
                height={"100%"}
                effect={"blur"}
                classes={"object-cover"}
              />
            </div>
          )}
          <div className="mb-4 mt-5">
            <ButtonFile
              functions={onImageChange}
              buttonLabel="Upload Image"
              buttonIconPosition=""
              buttonClasses={
                "!bg-white !text-slate-400 border border-slate-200"
              }
            />
          </div>
        </div>
      </div>
      <div className="w-full mt-3">
        <TextEditor
          width="100%"
          height={350}
          name={"description"}
          onChange={onchangeHandler}
        />
      </div>
    </AddEditLayoutModal>
  );
};

export default AddEditRecipeModal;
