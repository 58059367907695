import { useState, useEffect } from "react";
import Input from "../form/Input";
import AddEditLayoutModal from "../../containers/modals/AddEditLayoutModal";
import { postData } from "../../services/api";
import toastr from "toastr";

const AddEditNutritionModal = ({
  title = "",
  show = false,
  editData = {},
  setEditData = () => {},
  setShow = () => {},
  setList = () => {},
  addType = "ASC",
}) => {
  const [disabled, setDisabled] = useState();
  const [name, setName] = useState("");

  useEffect(() => {
    if (editData?._id) {
      setName(editData.title);
    }
  }, [editData]);

  const closeModal = () => {
    setShow(false);
    setName("");
    setDisabled(false);
    setEditData({});
  };

  const onSubmit = async () => {
    setDisabled(true)
    const res = await postData(`nutrition/${editData?._id ? 'edit'  : 'add'}`, { 

      title: name,
      id:  editData?._id || null,
      
    },editData?._id?"PUT":"POST")

    if (res.status === 200 || res.status===201) {
      if (!editData?._id) {
        if (addType !== 'DESC') {
          setList(list => ({
            ...list,
            data: [ ...list.data, res.data ],
            count: list.count + 1
          }))
        } else {
          setList(list => ({
            ...list,
            data: [ res.data, ...list.data, ],
            count: list.count + 1
          }))
        }
      } else if (editData && editData?._id) {
        setList(list => ({
          ...list,
          data: list.data.map(item => item._id !== editData._id ? item : res.data)
        }))
      }

      closeModal()
      toastr.success(res.message)
    } else {
      setDisabled(false)
      toastr.error(res.message)
    }
  }

  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      setShow={setShow}
      onClose={closeModal}
      disabled={disabled}
      onSubmit={onSubmit}
    >
      <div className="relative w-full">
        <Input
          label={"Nutrition Name"}
          labelClasses={"!text-xs"}
          inputType={"text"}
          inputPlaceholder={"Enter Nutrition Name"}
          inputValue={name}
          setInput={setName}
        />
      </div>
    </AddEditLayoutModal>
  );
};

export default AddEditNutritionModal;
