import { useState, useEffect,useRef } from "react";
import Input from "../form/Input";
import AddEditLayoutModal from "../../containers/modals/AddEditLayoutModal";
import { postData } from "../../services/api";
import toastr from "toastr";
import Select from "../form/Select";
import { useSelector } from "react-redux";
import Button from "../form/Button";
import DeletePopup from "../DeletePopup";
import { useDispatch } from "react-redux";
import InputDate from "../form/InputDate";

const AssignMealPlanModal = ({
  title = "",
  show = false,
  editData = {},
  setEditData = () => {},
  setShow = () => {},
  setList = () => {},
  addType = "ASC",
  isDeleteBtn = false,
  ...props
}) => {
  const fp = useRef()
  const dispatch = useDispatch();
  const user_id = useSelector((state) => {
    return state.auth.user._id;
  });
  const [disabled, setDisabled] = useState();
  const [recipeList, setRecipeList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [recipeId, setRecipeId] = useState("");
  const [recipeSelectedValue, setRecipeSelectedValue] = useState("");
  const [categorySelectedValue, setCategorySelectedValue] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const mealList = async () => {
    const res = await postData(
      `assign-meal-list?limit=${9999}&offset=${0}`,
      {},
      "GET"
    );

    if (res.status === 200) {
      let obj = [];
      res.data.list.forEach((ele, i) => {
        obj[i] = {
          id: ele._id,
          title: ele.title,
          start: ele.date?.split("T")[0],
        };
      });

      props.setInitialEvents(obj);
    }
  };
  useEffect(() => {
    if (editData?._id) {
      props.setMealTitle(editData.title);
      props.setDate(editData.date);
      setCategorySelectedValue(editData.category[0]._id);
      setRecipeSelectedValue(editData.recipe[0]._id);
      setCategoryId(editData.category[0]._id);
    }
  }, [editData]);

  useEffect(() => {
    if (show) {
      const categoryList = async () => {
        const res = await postData(
          `categories?type=main&for=recipe&status=${true}`,
          {}
        );

        if (res.status === 200) {
          const obj = [];
          obj[0] = {
            _id: "",
            label: "Categories",
          };
          res.data.list.forEach((ele, i) => {
            obj[i + 1] = {
              _id: ele._id,
              label: ele.name,
            };
          });

          setCategoryList(obj);
        }
      };

      categoryList();
    }
  }, [dispatch, show]);

  useEffect(() => {
    if (categoryId !== "") {
      const recipeList = async () => {
        const res = await postData(
          `recipe/list?category_id=${categoryId}&status=${true}`,
          {},
          "GET"
        );
        if (res.status === 200) {
          const obj = [];
          obj[0] = {
            _id: "",
            label: "All",
            isEnabled: false,
          };
          res.data.list.forEach((ele, i) => {
            obj[i + 1] = {
              _id: ele._id,
              label: ele.title,
              isEnabled: false,
            };
          });

          if (obj.length > 1) {
            setRecipeList(obj);
          } else {
            setRecipeList([]);
            setRecipeId("");
            setRecipeSelectedValue("");
          }
        }
      };

      recipeList();
    } else {
      setRecipeList([]);
    }
  }, [categoryId]);

  const closeModal = () => {
    setShow(false);
    setDisabled(false);
    setEditData({});
    props.setDate(new Date().toISOString().split("T")[0]);
    props.setMealTitle("");
    setCategoryId("");
    setCategorySelectedValue("");
    setRecipeSelectedValue("");
  };

  const onSubmit = async () => {
    setDisabled(true);

    const res = await postData(
      `${editData._id ? "assign-meal/edit" : "assign-meal"}`,
      {
        id: editData?._id || null,
        title: props.mealTitle,
        recipe_id: recipeId ? recipeId : recipeSelectedValue,
        category_id: categoryId ? categoryId : categorySelectedValue,
        user_id: user_id,
        date: props.date,
      },
      editData._id ? "PUT" : "POST"
    );

    if (res.status === 200 || res.state === 201) {
      props.setInitialEvents([]);
      mealList();
      setRecipeId("");
      props.setDate(new Date().toISOString().split("T")[0]);
      props.setMealTitle("");
      setCategoryId("");
      setCategorySelectedValue("");
      setRecipeSelectedValue("");

      if (!editData?._id) {
        if (addType !== "DESC") {
          setList((list) => ({
            ...list,
            data: [
              ...list.data,
              { ...res.data, date: res.data?.date?.split("T")[0] },
            ],
            count: list.count + 1,
          }));
        } else {
          setList((list) => ({
            ...list,
            data: [
              ...list.data,
              { ...res.data, date: res.data?.date?.split("T")[0] },
            ],
            count: list.count + 1,
          }));
        }
      } else if (editData && editData?._id) {
        setList((list) => ({
          ...list,
          data: list.data.map((item) =>
            item._id !== editData._id
              ? item
              : { ...res.data, date: res.data?.date?.split("T")[0] }
          ),
        }));
      }
      setDisabled(false);
      closeModal();
      toastr.success(res.message);
    } else {
      setDisabled(false);
      toastr.error(res.message);
    }
  };

  return (
    <>
      <AddEditLayoutModal
        title={title}
        show={show}
        setShow={setShow}
        onClose={closeModal}
        disabled={disabled}
        onSubmit={onSubmit}
        modalSize="m"
      >
        <div className="relative w-full">
          <div className="mb-4">
            <InputDate
              label={"Date"}
              labelClasses={"!text-xs"}
              value={props.date}
              placeholder={"Pick Date"}
              onChange={([date]) => {
                props.setDate(date.toISOString().split("T")[0]);
              }}
            />
          </div>
          {/* <Flatpickr
            data-enable-time
            ref={fp}
            value={props.date}
            onChange={([date]) => {
              props.setDate(date.toISOString().split("T")[0]);
            }}
          /> */}
          <div className="mb-4">
            <Input
              label={"Meal Name"}
              labelClasses={"!text-xs"}
              inputType={"text"}
              inputPlaceholder={"Enter Name"}
              inputValue={props.mealTitle}
              setInput={props.setMealTitle}
            />
          </div>

          <div className="mb-4">
            {categoryList.length > 0 && (
              <Select
                labelClasses={"!text-xs"}
                xPlacement={"left"}
                dropdownButtonClass={"!bg-white"}
                selectedValue={categorySelectedValue}
                dropdownData={categoryList}
                label={"Select Category"}
                getSelectedValue={(e) => setCategoryId(e._id)}
              />
            )}
          </div>
          <div className="mb-4">
            {recipeList.length > 0 && (
              <Select
                labelClasses={"!text-xs"}
                xPlacement={"left"}
                dropdownButtonClass={"!bg-white"}
                selectedValue={recipeSelectedValue}
                dropdownData={recipeList}
                label={"Select Recipe"}
                getSelectedValue={(e) => setRecipeId(e._id)}
              />
            )}
          </div>
          {props.calanderView && editData._id !== undefined && (
            <div className="flex items-center justify-center gap-4 mt-2">
              <Button
                buttonClasses="!bg-rose-500"
                buttonLabelClasses=""
                buttonType={"button"}
                buttonIcon={"fa-light fa-check"}
                buttonIconPosition={"left"}
                buttonLabel={"Delete"}
                functions={() => {
                  setShowDeleteModal(true);
                }}
                buttonHasLink={false}
              />
              <Button
                buttonLabelClasses=""
                buttonType={"button"}
                buttonIcon={"fa-light fa-copy"}
                buttonIconPosition={"left"}
                buttonLabel={"Copy"}
                buttonClasses={
                  "!bg-slate-200 !text-slate-600 border border-slate-300"
                }
                functions={() => {
                  //setShow(false);
                  fp.current.flatpickr.open();
                }}
                buttonHasLink={false}
              />
            </div>
          )}
        </div>
      </AddEditLayoutModal>

      
    </>
  );
};

export default AssignMealPlanModal;
