import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from './form/Button';
import { postData } from '../services/api';
import toastr from 'toastr';

const DeletePopup = ({
  isOpen = false,
  setIsOpen = () => {},
  currentId,
  setCurrentId = () => {},
  deleteLink = '',
  setDeleteLink = () => {},
  deletePayload = {},
  setDeletePayload = () => {},
  getActionValue = () => {},
  deleteAction = () => {},
}) => {
  const closeModal = () => {
    setIsOpen(false);
    setCurrentId(null);
    setDeleteLink(null);
    setDeletePayload({});
  };

  const onSubmit = async () => {
    getActionValue({
      button_type: 'delete',
      row_id: currentId,
      current_status: '',
    });

    if (deleteLink) {
      const res = await postData(deleteLink, deletePayload,"DELETE");
      if (res.status === 200) {
        toastr.success(res.message)

        if (deleteAction) {
          deleteAction((list) => ({
            ...list,
            data: list.data.filter((item) => item._id !== currentId),
          }));
        }

        // setDeleteAction(list => list.filter(item => item._id !== currentId))
        closeModal();
      } else {
        toastr.error(res.message);
      }
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-50' onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='w-full max-w-xs transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                <Dialog.Title
                  as='h3'
                  className='text-lg font-medium leading-6 text-gray-900 text-center'
                >
                  Delete Record
                </Dialog.Title>
                <div className='py-8 px-4'>
                  <p className='text-sm text-gray-500 text-center'>
                    You are trying to delete the record, click "Confirm" below.
                  </p>
                </div>

                <div className='flex items-center justify-center gap-4 mt-2'>
                  <Button
                    buttonClasses='!bg-rose-500'
                    buttonLabelClasses=''
                    buttonType={'button'}
                    buttonIcon={'fa-light fa-check'}
                    buttonIconPosition={'left'}
                    buttonLabel={'Confirm'}
                    functions={onSubmit}
                    buttonHasLink={false}
                  />
                  <Button
                    buttonLabelClasses=''
                    buttonType={'button'}
                    buttonIcon={'fa-light fa-times'}
                    buttonIconPosition={'left'}
                    buttonLabel={'Cancel'}
                    buttonClasses={
                      '!bg-slate-200 !text-slate-600 border border-slate-300'
                    }
                    functions={closeModal}
                    buttonHasLink={false}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default DeletePopup;
