import React, { useEffect, useState } from 'react';
import { setTitle } from '../helpers/MetaTag';
import Image from '../components/elements/Image';
import Button from '../components/form/Button';
import ButtonFile from '../components/form/ButtonFile';
import Input from '../components/form/Input';
import moment from 'moment';
import toastr from 'toastr';
import { fileUpload, postData } from "../services/api";
import { useDispatch } from "react-redux";
import { authUserSetup } from '../store/authSlice';

const AdminProfile = () => {
  setTitle('Zuso | Admin Profile');
  const dispatch = useDispatch();
  const [profileData, setProfileData] = useState({});
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isChanagePassword, setIsChanagePassword] = useState(false);
  const [isCancelChanagePassword, setIsCancelChanagePassword] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [showImage, setShowimage] = useState('');
  const [isUpdateImage, setIsUpdateImage] = useState(false);

  useEffect(() => {
    setName(profileData?.name);
    setEmail(profileData?.email);
    setPhone(profileData?.phone);
    setShowimage(profileData?.image)
    
  }, [profileData]);

  useEffect(() => {
    async function profileData() {
      try {
        const result = await postData("profile", {},'GET');
        if (result.status === 200) {
         setProfileData(result.data);
        } else {
          console.log('Profile Details message', result.message)
        }
      } catch (error) {
        console.log('Error profile details catch', error.message)
      }
    }
    profileData();
  }, []);

  const onCancel = async (e) => {
    setIsCancel(true);
    setName(profileData?.name);
    setPhone(profileData?.phone);
    setIsCancel(false);
  }

  const onUpdate = async (e) => {
    setIsUpdate(true);
    try {
      const result = await postData("profile/update", {
        name: name,
        phone: phone
      },'POST');
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        dispatch(authUserSetup(result.data));
        setIsUpdate(false);
      } else {
        toastr.error(result.message);
        setIsUpdate(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsUpdate(false);
    }
  }

  const onpasswordCancel = async (e) => {
    setIsCancelChanagePassword(true);
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setIsCancelChanagePassword(false);
  }

  const onChangePassword = async (e) => {
    setIsChanagePassword(true);
    try {
      const result = await postData("change-password", {
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmPassword: confirmPassword
      },'POST');
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsChanagePassword(false);
      } else {
        toastr.error(result.message);
        setIsChanagePassword(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsChanagePassword(false);
    }
  }

  const onImageChange = async (e) => {
    setIsUpdateImage(true);
    if (e.target.files.length) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const imgRes = await fileUpload("file-upload", formData, "POST");

      if (imgRes.status === 200) {
        setIsUpdateImage(false);
        setShowimage(imgRes?.location);
       await postData("profile/update-image", {
          image: imgRes?.location
        },'PUT');
      } else {
        setShowimage('');
      }
    }
  };

  
  return (
    <>
      <div className="relative flex justify-between items-center mb-4">
        <div className="text-3xl text-slate-800 font-bold">Profile</div>
      </div>
      <div className="relative grid grid-cols-4 gap-4">
        <div className="col-span-1">
          <div className="sticky top-12 bg-white rounded shadow">
            <div className="py-6 px-6 flex flex-col space-y-4 relative">
              <div className="w-32 h-32 min-w-[128px] overflow-hidden rounded-md border border-slate-200">
                <Image
                  src={showImage}
                  alt={'Admin'}
                  width={'100%'}
                  height={'100%'}
                  effect={'blur'}
                  classes={'object-cover'}
                  id={"profileImg"}
                />
              </div>
              <div className="w-full space-y-1">
                {profileData?.name &&
                  <div className="text-xl font-bold text-slate-900">{profileData?.name}</div>
                }
                {profileData?.role_details?.name &&
                  <div className="flex items-center space-x-2 text-slate-500 text-sm">
                    <i className="fa-solid fa-fw fa-briefcase"></i>
                    <div className="text-[13px] font-light"><span className="font-semibold">{profileData?.role_details?.name}</span></div>
                  </div>
                }

                <div className="flex items-center space-x-2 text-slate-500 text-sm">
                  <i className="fa-solid fa-fw fa-clock"></i>
                  <div className="text-[13px] font-light">Since <span className="font-semibold">{(profileData) ? moment(new Date(profileData?.created_at)).format('MMM YYYY') : 'N/A'}
                  </span>
                  </div>
                </div>

              </div>
              {
                (profileData?.role_details && profileData?.role_details.length > 0) &&
                <div className="w-full">
                  <div className="text-xs text-slate-400">Role</div>
                  <div className="text-sm text-slate-500">{profileData?.role_details[0]?.name}</div>
                </div>
              }
              <div className="w-full">
                <div className="text-xs text-slate-400">Email address</div>
                <div className="text-sm text-slate-500">{(profileData) ? profileData?.email : 'N/A'}</div>
              </div>
              {
                profileData?.phone &&
                <div className="w-full">
                  <div className="text-xs text-slate-400">Phone</div>
                  <div className="text-sm text-slate-500">{profileData?.phone}</div>
                </div>
              }
              <div className="w-full">
                <div className="text-xs text-slate-400">Last Login</div>
                <div className="text-sm text-slate-500">
                  {(profileData && profileData?.last_login) ? moment(new Date(profileData?.last_login)).format('Do MMM YYYY') : 'N/A'}
                </div>
              </div>
            </div>
            <div className="divide-x divide-slate-200 flex overflow-hidden rounded-b border-t border-slate-200">
              <ButtonFile
                buttonType={'button'}
                buttonIcon={(isUpdateImage) ? 'fa-light fa-spinner fa-spin' : 'fa-light fa-image'}
                buttonIconPosition={'left'}
                buttonLabel={'Change Avatar'}
                buttonClasses={'!bg-white !text-slate-500 rounded-none w-full hover:!bg-primary-500 hover:!text-white'}
                functions={onImageChange}
                accepts={'image/*'}
              />
            </div>
          </div>
        </div>
        <div className="col-span-3 space-y-4">
          <div className="relative bg-white rounded shadow divide-y divide-slate-200">
            <div className="py-8 px-6 flex flex-col">
              <div className="text-xl font-bold text-slate-900">Profile Information</div>
              <div className="space-y-4 mt-4">
                <div className="relative">
                  <Input
                    label={'Full Name'}
                    labelClasses={'!text-xs'}
                    inputType={'text'}
                    inputPlaceholder={'Enter name'}
                    inputValue={name}
                    setInput={setName}
                  />
                </div>
                <div className="flex items-start gap-4">
                  <div className="relative w-full">
                    <Input
                      label={'Email address'}
                      labelClasses={'!text-xs'}
                      inputType={'text'}
                      inputPlaceholder={'Enter email address'}
                      inputValue={email}
                      setInput={setEmail}
                      isDisabled={true}
                    />
                  </div>
                  <div className="relative w-full">
                    <Input
                      label={'Phone'}
                      labelClasses={'!text-xs'}
                      inputType={'text'}
                      inputPlaceholder={'Enter phone'}
                      inputValue={phone}
                      setInput={setPhone}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="py-3 px-3 border-t border-slate-200">
              <div className="flex items-center justify-end gap-4">
                <Button
                  buttonLabelClasses=''
                  buttonType={'button'}
                  buttonIcon={(isCancel) ? 'fa-light fa-spinner fa-spin' : 'fa-light fa-times'}
                  buttonIconPosition={'left'}
                  buttonLabel={'Cancel'}
                  buttonClasses={'!bg-slate-200 !text-slate-600 border border-slate-300'}
                  functions={onCancel}
                  buttonHasLink={false}
                  buttonDisabled={isCancel}
                />
                <Button
                  buttonClasses=''
                  buttonLabelClasses=''
                  buttonType={'button'}
                  buttonIcon={(isUpdate) ? 'fa-light fa-spinner fa-spin' : 'fa-light fa-check'}
                  buttonIconPosition={'left'}
                  buttonLabel={'Save'}
                  functions={onUpdate}
                  buttonHasLink={false}
                  buttonDisabled={isUpdate}
                />
              </div>
            </div>
          </div>
          <div className="relative bg-white rounded shadow divide-y divide-slate-200">
            <div className="py-8 px-6 flex flex-col">
              <div className="text-xl font-bold text-slate-900">Change Password</div>
              <div className="space-y-4 mt-4 max-w-md">
                <div className="relative">
                  <Input
                    label={'Old Password'}
                    labelClasses={'!text-xs'}
                    inputType={'password'}
                    inputPlaceholder={'Enter Old Password'}
                    inputValue={oldPassword}
                    setInput={setOldPassword}
    
                  />
                </div>
                <div className="relative w-full">
                  <Input
                    label={'New Password'}
                    labelClasses={'!text-xs'}
                    inputType={'password'}
                    inputPlaceholder={'New Password'}
                    inputValue={newPassword}
                    setInput={setNewPassword}
                  
                  />
                </div>
                <div className="relative">
                  <Input
                    label={'Retype Password'}
                    labelClasses={'!text-xs'}
                    inputType={'password'}
                    inputPlaceholder={'Retype Password'}
                    inputValue={confirmPassword}
                    setInput={setConfirmPassword}
    
                  />
                </div>
              </div>
            </div>
            <div className="py-3 px-3 border-t border-slate-200">
              <div className="flex items-center justify-end gap-4">
                <Button
                  buttonLabelClasses=''
                  buttonType={'button'}
                  buttonIcon={(isCancelChanagePassword) ? 'fa-light fa-spinner fa-spin' : 'fa-light fa-times'}
                  buttonIconPosition={'left'}
                  buttonLabel={'Cancel'}
                  buttonClasses={'!bg-slate-200 !text-slate-600 border border-slate-300'}
                  functions={onpasswordCancel}
                  buttonHasLink={false}
                  buttonDisabled={isCancelChanagePassword}
                />
                <Button
                  buttonClasses=''
                  buttonLabelClasses=''
                  buttonType={'button'}
                  buttonIcon={(isChanagePassword) ? 'fa-light fa-spinner fa-spin' : 'fa-light fa-check'}
                  buttonIconPosition={'left'}
                  buttonLabel={'Save'}
                  functions={onChangePassword}
                  buttonHasLink={false}
                  buttonDisabled={isCancelChanagePassword}
                />
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default AdminProfile;