import { useState, useEffect } from 'react';
import Input from '../form/Input';
import AddEditLayoutModal from '../../containers/modals/AddEditLayoutModal';
import { postData } from '../../services/api';
import toastr from 'toastr';
import Image from '../elements/Image';
import Select from '../form/Select';

const AddEditGroceryModal = ({
  title = '',
  show = false,
  editData = {},
  setEditData = () => { },
  setShow = () => { },
  setList = () => { },
  addType = 'ASC'
}) => {
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState('');
  const [image, setImage] = useState()
  const [groceryList, setGroceryList] = useState([])
  const [selectedVal, setSelectedVal] = useState("");
  const [selectedValue, setGetSelectedValue] = useState()
  
  const groceryListData = async () => {
    const res = await postData(`categories?for=grocery`, {
    });

    if (res.status === 200) {
      const obj = [
        {
          _id: "",
          label: "Groceries",
          value: ""
        }
      ]

      Array.isArray(res.data.list) &&
        res.data.list.forEach((item, i) => {
          obj[i + 1] = {
            _id: item._id,
            label: item.name,
            value: item._id,
          };
        });

      setGroceryList(obj)
    }


  }

  useEffect(() => {
    if (show) {
      groceryListData()

    }
  }, [show])

  useEffect(() => {
    if (editData?._id) {
      setName(editData.name)
      setSelectedVal(editData?.category[0]._id)


    } else {
      setName("")
      setSelectedVal("")

    }

  }, [editData])


  const closeModal = () => {
    setShow(false);
    setLoader(false)
    setName('')
    setEditData({})
    setImage('')
    setSelectedVal("")

  };

  const onSubmit = async () => {

    setLoader(true)

    const res = await postData(`grocery/${editData?._id ? 'edit' : 'add'}`, {
      name: name,
      id: editData?._id || null,
      category_id: selectedValue

    },editData._id?"PUT":"POST")

    if (res.status === 200 || res.status===201) {
      setName("")
      if (!editData?._id) {
        if (addType !== 'DESC') {
          setList(list => ({
            ...list,
            data: [...list.data, res.data],
            count: list.count + 1
          }))
        } else {
          setList(list => ({
            ...list,
            data: [res.data, ...list.data,],
            count: list.count + 1
          }))
        }
      } else if (editData && editData?._id) {
        setList(list => ({
          ...list,
          data: list.data.map(item => item._id !== editData._id ? item : res.data)
        }))
      }
      setLoader(false)
      closeModal()
      toastr.success(res.message)
    } else {
      setLoader(false)
      toastr.error(res.message)
    }
  }

  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      setShow={setShow}
      onClose={closeModal}
      disabled={loader}
      onSubmit={onSubmit}
      modalSize="m"
    >

      <div className='w-full'>
        {
          image ?
            <>
              {image !== null && image instanceof File ? (
                <div className="relative w-full">
                  <div
                    className="top-1 left-1 cursor-pointer"
                    onClick={() => setImage(null)}
                  >
                    X
                  </div>
                  <Image src={URL.createObjectURL(image)} alt={name} />
                </div>
              ) : (
                <div className="relative w-full">
                  <div
                    className="top-1 left-1 cursor-pointer"
                    onClick={() => setImage(null)}
                  >
                    X
                  </div>
                  <div className="relative w-full">
                    <Image src={image} alt={name} />
                  </div>
                </div>
              )

              }
            </>
            :
            ""
        }
        <div className='mb-4'>

          <Input
            label={'Grocery Name'}
            labelClasses={'!text-xs'}
            inputType={'text'}
            inputPlaceholder={'Enter Grocery Name'}
            inputValue={name}
            setInput={setName}
          />
        </div>
          {
            groceryList.length > 0 &&
            <div className='mb-4'>
            <Select
              label={'Category'}
              labelClasses={'!text-xs'}
              xPlacement={"left"}
              dropdownButtonClass={"!bg-white"}
              transitionClass={"!w-auto"}
              selectedValue={selectedVal}
              dropdownData={groceryList}
              getSelectedValue={(e) => setGetSelectedValue(e.value)}
            />
            </div>
          }

        
      </div>
    </AddEditLayoutModal>
  );
};

export default AddEditGroceryModal;
