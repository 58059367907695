import React from 'react'
import Select from '../../form/Select'

function SearchCategories({
    data=[],
    handleSelect=()=>{}
}
    

) {
  return (<>
  
  {
                  data.length>0 && 
                   <Select
                      xPlacement={"left"}
                      dropdownButtonClass={"!bg-white"}
                      selectedValue={""}
                      dropdownData={data}
                      label={""}
                      getSelectedValue={(e) =>handleSelect(e._id,e.label)}
                    />
    }
  </>
    
        
  )
}

export default SearchCategories