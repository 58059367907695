import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TableAlt from "../components/elements/table/TableAlt";
import ListLayout from "../containers/ListLayout";
import { postData } from "../services/api";
import toastr from "toastr";
import AddEditRecipeModal from "../components/modals/AddEditRecipeModal";
import SearchCategories from "../components/elements/search/SearchCategories";
import MultipalSearch from "../components/elements/search/MultipalSearch";
import { getQueriesParams } from "../helpers";
import AddEditMealTimesModal from "../components/modals/AddEditMealTimesModal";

const MealTimes = () => {
  const user = useSelector((state) => state.auth.user);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const [list, setList] = useState({
    loading: true,
    data: [],
    count: 0,
  });
  const [statusFilterData, setStatusFilterData] = useState([
    { _id: 1, name: "Active", type: "checkbox", isChecked: true },
    { _id: 2, name: "Inactive", type: "checkbox", isChecked: false },
  ]);
  const [sortField, setSortField] = useState("ordering");
  const [sortType, setSortType] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [searchCategory, setSearchCategory] = useState([]);
  const [searchNutrition, setSearchNutrition] = useState([]);
  const [searchIngredient, setSearchIngredient] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [nutritionId, setNutritionId] = useState([]);
  const [ingredientId, setIngredientId] = useState([]);
  const [searchSubCategory, setSearchSubCategory] = useState([]);
  const [subCategoriesId, setSubCategoriesId] = useState([]);

  //   after modal close ========>

  useEffect(() => {
    if (!showModal) {
      setEditData({});
    }
  }, [showModal]);

  //   list fetch function and api calling ==============>

  useEffect(() => {
    let query = getQueriesParams({
      limit,
      offset,
      sortQuery: sortType == "-1" ? "-" + sortField : sortField,
    });
    if (user?.email) {
      const loadData = async () => {
        setList((list) => ({ ...list, loading: true }));
        const searchParams = {};
        if (keyword) {
          searchParams.title = { $regex: keyword, $options: "i" };
        }

        const res = await postData(`meal-types/${query}`, {}, "GET");
        if (res.status === 200) {
          setList((list) => ({
            data: res?.docs,
            loading: false,
            count: res?.totalDocs,
          }));
        } else {
          setList((list) => ({ ...list, loading: false }));
        }
      };

      loadData();
    }
  }, [limit, offset, sortType]);

  const tableHeadData = [
    {
      _id: 2,
      width: 35,
      title: "Name",
      name: "name",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 5,
      width: 10,
      title: "Status",
      name: "status",
      align: "left",
      sortable: true,
      filterable: true,
      filterOptions: statusFilterData,
      onFilterChange: (id) => {
        setStatusFilterData((data) =>
          data.map((item) =>
            item._id !== id
              ? item
              : {
                  ...item,
                  isChecked: !item.isChecked,
                }
          )
        );
      },
    },
    {
      _id: 4,
      width: 15,
      title: "Created At",
      name: "createdAt",
      align: "left",
      sortable: true,
      dateFilterable: true,
      onDateChange: (e) => {
        if (e && e.length && e.length > 1) {
          setDateRange(e);
        }
      },
    },
    {
      _id: 6,
      width: 10,
      title: "",
      name: "",
      align: "right",
      sortable: false,
      filterable: false,
    },
  ];

  const changeStatus = async (id) => {
    const res = await postData(
      "meal-types/status-change",
      {
        ids: [id],
        status: list.data.find((item) => item._id === id).status === 1 ? 2 : 1,
      },
      "PATCH"
    );

    if (res.status === 200) {
      setList((list) => ({
        ...list,
        data: list.data.map((item) =>
          item._id !== id
            ? item
            : {
                ...item,
                status:
                  list.data.find((item) => item._id === id).status === 1
                    ? 2
                    : 1,
              }
        ),
      }));
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  const actionData = (item) => [
    {
      name: "Edit",
      icon: "fa-pen-to-square",
      type: "button",
      onClick: (id) => {
        setShowModal(true);
        setEditData(list.data.find((item) => item._id === id) || {});
      },
    },
    {
      name: item.status === 1 ? "Inactive" : "Active",
      icon: item.status === 1 ? "fa-circle-exclamation" : "fa-circle-check",
      type: "button",
      onClick: changeStatus,
    },
    {
      name: "Delete",
      icon: "fa-trash-can",
      type: "button",
      actionType: "delete",
      deleteLink: `/meal-types/${item._id}`,
      deletePayload: { id: item._id },
      standout: true,
    },
  ];

  const onDrag = async (data = []) => {
    const res = await postData(
      "meal-types/save-ordering",
      {
        data: data.map((item, i) => ({ id: item._id, ordering: i })),
      },
      "PATCH"
    );

    if (res.status !== 200) {
      toastr.error(res.message);
    } else {
      toastr.success(res.message);
    }
  };

  return (
    <>
      <ListLayout
        title="Meal Times"
        addName="Add Meal Times"
        onAdd={() => setShowModal(true)}
        limit={limit}
        setLimit={setLimit}
        setKeyword={setKeyword}
        keyword={keyword}
        noOfPage={Math.ceil(list.count / limit)}
        onPageChange={(val) => setOffset(val.selected * limit)}
        searchCategory={
          <SearchCategories
            data={searchCategory}
            handleSelect={(id) => {
              setCategoryId(id);
            }}
          />
        }
      >
        <TableAlt
          tableHeadData={tableHeadData}
          tableData={list.data}
          loading={list.loading}
          sortField={sortField}
          sortType={sortType}
          setSortField={setSortField}
          setSortType={setSortType}
          actionData={actionData}
          setList={setList}
          isDraggable={true}
          onDrag={onDrag}
        />
      </ListLayout>

      <AddEditMealTimesModal
        show={showModal}
        setShow={setShowModal}
        title={
          Object.keys(editData).length > 0
            ? "Edit Meal Times"
            : "Add Meal Times"
        }
        setList={setList}
        editData={editData}
        addType={"ASC"}
      />
    </>
  );
};

export default MealTimes;
