import React, { useState } from 'react';
import Checkbox from './Checkbox';
const DropdownCheckbox = ({data, functions = () => {}, ...props}) => {
    const [isOpen, setIsOpen] = useState(false);
    const count = data.filter((item) => item.isEnabled).length;
    return (
        <>
            <div className="relative">
                <button 
                type="button" 
                className="relative w-full h-10 focus:ring-0 transition-all duration-200 gap-2 pr-2 pl-3 bg-white rounded border border-slate-200 flex items-center cursor-pointer text-left " 
                onClick={() => setIsOpen(!isOpen)}
                >
                    <div className="text-[13px] text-slate-500 font-medium whitespace-nowrap truncate w-full">{(count>0)?'Selected '+count:props.label}</div>
                    <div className="text-xs text-slate-400">
                        <i className={"fa-light fa-fw fa-chevron-down"}></i>
                    </div>
                </button>
                <div className={"absolute top-full left-0 w-full min-w-[200px] bg-white rounded shadow mt-1 transition-all duration-200 z-10" + (isOpen?"":" opacity-0 invisible")}>
                    <div className="overflow-auto max-h-[200px] smooth-scroll scrollbar py-2">
                    {data.map((item, index) => (
                        <div className="px-3 py-1" key={index}>
                            <Checkbox
                            checkboxName={item?.label}
                            isChecked={item?.isEnabled}
                            checkboxLabel={item?.label}
                            checkboxValue={item?._id}
                            getCheckboxValue={(id,event) => functions(id,event.target.checked) }
                            checkboxLableClass={"!text-xs"} 
                            />
                        </div>
                    ))
                    }
                    </div>
                </div>
            </div>
        </>
    );
};

export default DropdownCheckbox;