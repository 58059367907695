import React, { useEffect, useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import { postData } from "../services/api";

const Dashboard = () => {
  setTitle("Zuso | Dashboard");

  const [totalUsers, setTotalUsers] = useState("");
  const [totalAdmins, setTotalAdmins] = useState("");
  const [totalCategories, setTotalCategories] = useState("");
  const [totalSubCategories, setTotalSubCategories] = useState("");
  const [totalRecipes, setTotalRecipes] = useState("");
  const [totalMealPlans, setTotalMealPlans] = useState("");

  useEffect(() => {
    const loadData = async () => {
      const res = await postData('/all-data-count')
      if (res.status === 200) {
        setTotalUsers(res.data.totalUsers);
        setTotalAdmins(res.data.totalAdmins);
        setTotalCategories(res.data.totalCategories);
        setTotalSubCategories(res.data.totalSubCategories);
        setTotalRecipes(res.data.totalRecipes);
        setTotalMealPlans(res.data.totalMealPlans);
      }
    }
    loadData()
  }, []);

  const statisticsData = [
    {
      _id:1,
      label:"Users",
      data:totalUsers,
      icon:"fa-users",
      iconColor:"text-rose-500",
    },
    {
      _id:2,
      label:"Admins",
      data:totalAdmins,
      icon:"fa-user-crown",
      iconColor:"text-amber-500",
    },
    {
      _id:3,
      label:"Categories",
      data:totalCategories,
      icon:"fa-grid-2-plus",
      iconColor:"text-green-500",
    },
    {
      _id:4,
      label:"Sub Categories",
      data:totalSubCategories,
      icon:"fa-gallery-thumbnails",
      iconColor:"text-cyan-500",
    },
    {
      _id:5,
      label:"Recipes",
      data:totalRecipes,
      icon:"fa-mortar-pestle",
      iconColor:"text-blue-500",
    },
    {
      _id:6,
      label:"Meal Plans",
      data:totalMealPlans,
      icon:"fa-cutlery",
      iconColor:"text-purple-500",
    },
  ]


  return (
    <>
      <div className="relative flex justify-between items-center mb-4">
        <div className="text-3xl text-slate-800 font-bold">Dashboard</div>
      </div>
      {/* <div className="grid grid-cols-6 gap-2 mb-5">
        {statisticsData.map((item) => (
        <div className="relative" key={item._id}>
          <div className="relative bg-white shadow rounded py-5 px-3 h-full">
            <div className="flex items-center justify-between h-full">
              <div className={"w-12 h-12 flex items-center justify-center text-4xl " + item.iconColor}>
                <i className={"fa-sharp fa-fw " + item.icon}></i>
              </div>
              <div className="w-full max-w-[calc(100%-60px)] text-right">
                <div className="text-2xl font-semibold text-slate-800">{item.data}</div>
                <div className="uppercase text-xs text-slate-500">{item.label}</div>
              </div>
            </div>
          </div>
        </div>
        ))}
      </div> */}
    </>
  );
};

export default Dashboard;
