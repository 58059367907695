import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TableAlt from "../components/elements/table/TableAlt";
import ListLayout from "../containers/ListLayout";
import { postData } from "../services/api";
import AssignMealPlanModal from "../components/modals/AssignMealPlanModal";
import SearchCategories from "../components/elements/search/SearchCategories";

const MealPlans = () => {
  const user = useSelector((state) => state.auth.user);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [list, setList] = useState({
    loading: true,
    data: [],
    count: 0,
  });
  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [searchRecipes, setSearchRecipes] = useState([]);
  const [recipeId, setRecipeId] = useState("");
  const [SearchCategory, setSearchCategory] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [mealTitle, setMealTitle] = useState("");
  const [dateRange, setDateRange] = useState([]);


  useEffect(() => {
    const searchRecipeList = async () => {
      const res = await postData(`recipe/list?status=${true}`, {
      });

      if (res.status === 200) {
        const obj = [];
        obj[0] = {
          _id: "",
          label: "Recipes",
          value: "",
        };
        res.data.list.forEach((ele, i) => {
          obj[i + 1] = {
            _id: ele._id,
            label: ele.title,
          };
        });
        setSearchRecipes(obj);
      }
    };
    const searchCategoryList = async () => {
      const res = await postData(`categories?type=main&for=recipe&status=${true}`, {});

      if (res.status === 200) {
        const obj = [];
        obj[0] = {
          _id: "",
          label: "Categories",
          value: "",
        };
        res.data.list.forEach((ele, i) => {
          obj[i + 1] = {
            _id: ele._id,
            label: ele.name,
          };
        });
        setSearchCategory(obj);
      }
    };

    searchRecipeList();
    searchCategoryList();
  }, []);

  useEffect(() => {
    const loadList = async () => {
      setList((list) => ({ ...list, loading: true }));
      const searchParams = {};
      if (keyword) {
        searchParams.title = { $regex: keyword, $options: "i" };
      }
      const res = await postData(
        `assign-meal-list?keyword=${keyword}&keyword_key=title&dateRange[start]=${
          dateRange[0] ? dateRange[0] : ""
        }&dateRange[end]=${
          dateRange[1] ? dateRange[1] : ""
        }&recipe_id=${recipeId}&category_id=${categoryId}&limit=${limit}&offset=${offset}`,
        {},
        "GET"
      );
      if (res.status === 200) {
        let obj = [];
        res.data?.list.forEach((ele, i) => {
          obj[i] = {
            ...ele,
            date: ele.date?.split("T")[0],
          };
        });

        setList((list) => ({
          ...list,
          data: obj,
          loading: false,
          count: res.data.count,
        }));
      } else {
        setList((list) => ({ ...list, loading: false }));
      }
    };

    loadList();
  }, [
    user,
    keyword,
    limit,
    offset,
    sortField,
    sortType,
    categoryId,
    recipeId,
    dateRange,
  ]);

  const tableHeadData = [
    {
      _id: 1,
      width: 15,
      title: "Meal Name",
      name: "title",
      align: "left",
      sortable: false,
      filterable: false,
    },

    {
      _id: 2,
      width: 15,
      title: "Category",
      name: "category",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 3,
      width: 35,
      title: "Recipe",
      name: "recipe",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 4,
      width: 15,
      title: "Created At",
      name: "created_at",
      align: "left",
      sortable: true,
      dateFilterable: true,
      onDateChange: (e) => {
        if (e && e.length && e.length > 1) {
          setDateRange(e);
        }
      },
    },

    {
      _id: 5,
      width: 35,
      title: "Date",
      name: "date",
      align: "left",
      sortable: true,
      filterable: false,
    },
  ];

  const actionData = (item) => [
    {
      name: "Edit",
      icon: "fa-pen-to-square",
      type: "button",
      onClick: (id) => {
        setShowModal(true);
        setEditData(list.data.find((item) => item._id === id) || {});
      },
    },
    {
      name: "Delete",
      icon: "fa-trash-can",
      type: "button",
      actionType: "delete",
      deleteLink: `assign-meal/delete/${item?._id}`,
      standout:true
    },
  ];

  return (
    <>
      <ListLayout
        title="Meal Plans"
        addName="Add Meal Plan"
        onAdd={() => setShowModal(true)}
        limit={limit}
        setLimit={setLimit}
        keyword={keyword}
        setKeyword={setKeyword}
        noOfPage={Math.ceil(list.count / limit)}
        totalCount={list.count}
        onPageChange={(val) => setOffset(val.selected * limit)}
        disableButton={false}
        searchRecipe={
          <SearchCategories
            data={searchRecipes}
            handleSelect={(id) => {
              setRecipeId(id);
            }}
          />
        }
        searchCategory={
          <SearchCategories
            data={SearchCategory}
            handleSelect={(id) => {
              setCategoryId(id);
            }}
          />
        }
      >
        <TableAlt
          tableHeadData={tableHeadData}
          tableData={list.data}
          loading={list.loading}
          sortField={sortField}
          sortType={sortType}
          setSortField={setSortField}
          setSortType={setSortType}
          actionData={actionData}
          setList={setList}
          hasActions={true}
        />
      </ListLayout>
      <AssignMealPlanModal
        show={showModal}
        setShow={setShowModal}
        title={
          Object.keys(editData).length > 0 ? "Edit Meal Plan" : "Add Meal Plan"
        }
        setList={setList}
        editData={editData}
        setEditData={setEditData}
        mealTitle={mealTitle}
        setMealTitle={setMealTitle}
        date={date}
        setDate={setDate}
        setInitialEvents={()=>{}}
      />
    </>
  );
};

export default MealPlans;
