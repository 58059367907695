import { useState, useEffect } from "react";
import Input from "../form/Input";
import AddEditLayoutModal from "../../containers/modals/AddEditLayoutModal";
import { fileUpload, postData } from "../../services/api";
import toastr from "toastr";
import ButtonFile from "../form/ButtonFile";
import Button from "../form/Button";
import Image from "../elements/Image";

const AddEditAdminModal = ({
  title = "",
  show = false,
  editData = {},
  setEditData = () => { },
  setShow = () => { },
  setList = () => { },
  addType = "ASC",
}) => {
  const [disabled, setDisabled] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (editData?._id) {
      setName(editData?.name);
      setEmail(editData?.email);
      setPassword(editData?.password)
      setImage(editData?.image)
    }
  }, [editData]);

  const closeModal = () => {
    setShow(false);
    setName("");
    setDisabled(false);
    setEditData({});
    setEmail("")
    setPassword("")
    setImage("")
  };
  const onImageChange = async (e) => {
    setDisabled(true);
    setImage(e.target.files[0]);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const imgRes = await fileUpload("file-upload", formData, "POST");
    if (imgRes.status === 200) {
      setImage(imgRes?.location);
      setDisabled(false);
    } else {
      setDisabled(false);
    }
  };
  const onSubmit = async () => {
    setDisabled(true);
    let url = `users`;
    if(editData?._id){
      url = `users/`+editData?._id;
    }
    const res = await postData(url, {
      id: editData?._id || null,
      name: name,
      email: email,
      image:image,
      password: password,
      type: "admin",
      email_verified: 1
    },editData?._id?"PUT" : "POST");

    if (res.status === 201 || res.status === 200) {
      setName("");
      setEmail("");
      setPassword("");
      if (!editData?._id) {
        if (addType !== "DESC") {
          setList((list) => ({
            ...list,
            data: [...list.data, res.data],
            count: list.count + 1,
          }));
        } else {
          setList((list) => ({
            ...list,
            data: [res.data, ...list.data],
            count: list.count + 1,
          }));
        }
      } else if (editData && editData?._id) {
        setList((list) => ({
          ...list,
          data: list.data.map((item) =>
            item._id !== editData._id ? item : res.data
          ),
        }));
      }

      closeModal();
      toastr.success(res.message);
    } else {
      setDisabled(false);
      toastr.error(res.message);
    }
  };

  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      setShow={setShow}
      onClose={closeModal}
      disabled={disabled}
      onSubmit={onSubmit}
      modalSize="m"
    >
      <div className="relative w-full">
        <div className="mb-4">
          <Input
            label={"Name"}
            labelClasses={"!text-xs"}
            inputType={"text"}
            inputPlaceholder={"Enter Name"}
            inputValue={name}
            setInput={setName}
          />
        </div>
        <div className="mb-4">
          <Input
            label={"Admin Email"}
            labelClasses={"!text-xs"}
            inputType={"text"}
            inputPlaceholder={"Enter Email"}
            inputValue={email}
            setInput={setEmail}
          />
        </div>
        <div className="mb-4">
          <Input
            label={"Admin Password"}
            labelClasses={"!text-xs"}
            inputType={"password"}
            inputPlaceholder={"Enter RePassword"}
            inputValue={password}
            setInput={setPassword}
          />
        </div>
        <div className="mb-4">
          <ButtonFile
            functions={onImageChange}
            buttonLabel="Upload Image"
            buttonIconPosition=""
            buttonClasses={"!bg-white !text-slate-400 border border-slate-200"}
          />
        </div>
        {image ? (
          <>
            {image !== null && image instanceof File ? (
              <div className="relative w-full rounded border border-slate-200 flex overflow-hidden">
                <Button
                  buttonClasses={
                    "!absolute !z-10 !top-1 !right-1 !cursor-pointer !bg-white !text-slate-400 !w-6 !h-6 !rounded-full !shadow !text-sm"
                  }
                  buttonType={"button"}
                  buttonIcon={"fa-light fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonHasLink={false}
                  functions={() => setImage(null)}
                />
                <Image
                  src={URL.createObjectURL(image)}
                  alt={name}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={"object-cover"}
                />
              </div>
            ) : (
              <div className="relative w-full rounded border border-slate-200 flex overflow-hidden">
                <Button
                  buttonClasses={
                    "!absolute !z-10 !top-1 !right-1 !cursor-pointer !bg-white !text-slate-400 !w-6 !h-6 !rounded-full !shadow !text-sm"
                  }
                  buttonType={"button"}
                  buttonIcon={"fa-light fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonHasLink={false}
                  functions={() => setImage(null)}
                />
                <Image
                  src={image}
                  alt={name}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={"object-cover"}
                />
              </div>
            )}
          </>
        ) : (
          ""
        )}
      </div>
    </AddEditLayoutModal>
  );
};

export default AddEditAdminModal;
