import { useState, useEffect } from "react";
import Input from "../form/Input";
import AddEditLayoutModal from "../../containers/modals/AddEditLayoutModal";
import { fileUpload, postData } from "../../services/api";
import toastr from "toastr";
import Select from "../form/Select";
import Image from "../elements/Image";
import ButtonFile from "../form/ButtonFile";
import Button from "../form/Button";

const AddEditSubCategoryModal = ({
  title = "",
  show = false,
  editData = {},
  setEditData = () => {},
  setShow = () => {},
  setList = () => {},
  addType = "ASC",
}) => {
  const [name, setName] = useState("");
  const [selectedVal, setSelectedVal] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [image, setImage] = useState("");
  const [loader, setLoader] = useState(false);
  const [selectedValue, setGetSelectedValue] = useState();

  useEffect(() => {
    if (editData !== {}) {
      setName(editData.name);
      setImage(editData?.image);
      setSelectedVal(editData?.parent_id);
    }
  }, [show, editData]);

  useEffect(() => {
    const loadCategoryList = async () => {
      const res = await postData(
        `categories?type=1&for=recipe&limit=9999`,
        {}
      );

      const obj = [
        {
          _id: "",
          label: "Select",
          value: "",
        },
      ];
      if (res.status === 200) {
        Array.isArray(res.docs) &&
          res.docs.forEach((item, i) => {
            obj[i + 1] = {
              _id: item._id,
              label: item.name,
              value: item._id,
            };
          });

        setCategoryList(obj);
      }
    };

    loadCategoryList();
  }, []);

  const closeModal = () => {
    setShow(false);
    setName("");
    setEditData({});
    setImage("")
    setLoader(false);
  };

  const onImageChange = async (e) => {
    setLoader(true);
    setImage(e.target.files[0]);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const imgRes = await fileUpload("file-upload", formData, "POST");
    if (imgRes.status === 200) {
      setImage(imgRes?.location);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const onSubmit = async () => {
    const res = await postData(
      `category/${editData?._id ? "edit" : "add"}`,
      {
        type: "sub",
        name: name,
        image: image,
        id: editData?._id || null,
        parent_id: selectedValue,
      },
      editData?._id ? "PUT" : "POST"
    );

    if (res.status === 200 || res.status === 201) {
      if (!editData?._id) {
        if (addType !== "DESC") {
          setList((list) => ({
            ...list,
            data: [...list.data, res.data],
            count: list.count + 1,
          }));
        } else {
          setList((list) => ({
            ...list,
            data: [res.data, ...list.data],
            count: list.count + 1,
          }));
        }
      } else if (editData && editData?._id) {
        setList((list) => ({
          ...list,
          data: list.data.map((item) =>
            item._id !== editData._id ? item : res.data
          ),
        }));
      }

      closeModal();
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      setShow={setShow}
      onClose={closeModal}
      disabled={loader}
      onSubmit={onSubmit}
      modalSize="m"
    >
      <div className="w-full">
        <div className="mb-4">
          <Input
            label={"Sub Category Name"}
            labelClasses={"!text-xs my-1"}
            inputType={"text"}
            inputPlaceholder={"Enter Sub Category Name"}
            inputValue={name}
            setInput={setName}
          />
        </div>
        <div className="mb-4">
          {editData !== {} && selectedVal !== "" && (
            <Select
              label={"Category"}
              labelClasses={"!text-xs"}
              xPlacement={"left"}
              dropdownButtonClass={"!bg-white"}
              transitionClass={"!w-auto"}
              selectedValue={selectedVal}
              dropdownData={categoryList}
              getSelectedValue={(e) => setGetSelectedValue(e.value)}
            />
          )}
        </div>
        <div className="mb-4">
          <ButtonFile
            functions={onImageChange}
            buttonLabel="Upload Image"
            buttonIconPosition=""
            buttonClasses={"!bg-white !text-slate-400 border border-slate-200"}
          />
        </div>
        {image ? (
          <>
            {image !== null && image instanceof File ? (
              <div className="relative w-full rounded border border-slate-200 flex overflow-hidden">
                <Button
                  buttonClasses={
                    "!absolute !z-10 !top-1 !right-1 !cursor-pointer !bg-white !text-slate-400 !w-6 !h-6 !rounded-full !shadow !text-sm"
                  }
                  buttonType={"button"}
                  buttonIcon={"fa-light fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonHasLink={false}
                  functions={() => setImage(null)}
                />
                <Image
                  src={URL.createObjectURL(image)}
                  alt={name}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={"object-cover"}
                />
              </div>
            ) : (
              <div className="relative w-full rounded border border-slate-200 flex overflow-hidden">
                <Button
                  buttonClasses={
                    "!absolute !z-10 !top-1 !right-1 !cursor-pointer !bg-white !text-slate-400 !w-6 !h-6 !rounded-full !shadow !text-sm"
                  }
                  buttonType={"button"}
                  buttonIcon={"fa-light fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonHasLink={false}
                  functions={() => setImage(null)}
                />
                <Image
                  src={image}
                  alt={name}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={"object-cover"}
                />
              </div>
            )}
          </>
        ) : (
          ""
        )}
      </div>
    </AddEditLayoutModal>
  );
};

export default AddEditSubCategoryModal;
