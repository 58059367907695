import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';

const SidebarSubMenuItem = ({ menuIcon, menuIconColor, menuLabel, submenuData, isMenuOpen, ...props }) => {
  const [submenuToggle, setSubmenuToggle] = useState(false);
  const handleToggle = () =>{
    setSubmenuToggle(!submenuToggle);
  }
  return (
    <>
      <button type="button"
        className={"group flex w-full items-center gap-1 text-left hover:bg-primary-500 rounded overflow-hidden p-1 transition-all duration-200"}
        onClick={handleToggle}
        title={menuLabel}
      >
        <div className={"w-8 min-w-[32px] h-8 flex justify-center items-center group-hover:text-white text-lg transition-all duration-200 " + (menuIconColor)}>
          <i className={"fa-light fa-fw " + (menuIcon)}></i>
        </div>
        {!isMenuOpen &&
        <div className="text-white w-full text-sm">{menuLabel}</div>
        }
        <div className={"w-8 min-w-[32px] h-8 flex justify-center items-center text-white/50 text-xs transition-all duration-200 " + (submenuToggle?"rotate-90":"")}>
          <i className={"fa-light fa-fw fa-chevron-right"}></i>
        </div>
      </button>
      <div className={"space-y-0.5 transition-all duration-200 " + (submenuToggle?"opacity-100 visible max-h-96 !mb-4 ":"opacity-0 invisible max-h-0 !my-0 ") + (!isMenuOpen?"ml-4 pl-2 border-l-2 border-white/20":"")}>
        {submenuData?.map((items) => (
          <Link 
          key={items._id} 
          to={items.menuLink} 
          className={"text-white font-light flex items-center text-sm px-3 py-2 rounded opacity-50 hover:opacity-100 transition-all duration-200 hover:bg-primary-500/50"}
          title={items.menuLabel}
          >
            {!isMenuOpen?items.menuLabel:<i className='fa-light fa-fw fa-circle-dot'></i>}
          </Link>
        ))}
      </div>
    </>
  );
};

export default SidebarSubMenuItem;