import React, { useState} from "react";
import { setTitle } from "../helpers/MetaTag";
import Image from "../components/elements/Image";
import Button from "../components/form/Button";
import Input from "../components/form/Input";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { postData } from "../services/api";
import { authLogin } from "../store/authSlice";
import { Link } from 'react-router-dom';
import toastr from "toastr";

const Login = () => {
  setTitle("Zuso | Login");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLogin, setIsLogin] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLogin(true);
    try {
      const userData = await postData("login", {
        
        login_type: 'email',
        email: email,
        password: password,
      },"POST");
      if (userData.status && userData.status === 200) {
        
        setIsLogin(false);
        dispatch(authLogin(userData.data));
        localStorage.setItem("accessToken", userData?.accessToken);
        localStorage.setItem("role", userData?.data?.code);
        toastr.success(userData.message);
        return navigate("/dashboard");
      } else {
        toastr.error(userData.message);
        setIsLogin(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsLogin(false);
    }
  };

  return (
    <>
      <div className="w-full min-h-screen flex bg-slate-900 relative">
        <div className="fixed top-0 left-0 w-full h-full">
          <Image
            src={"bg-login.jpg"} 
            alt={'Admin'} 
            width={'100%'} 
            height={'100%'} 
            effect={'blur'} 
            classes={'object-cover'}
          />
        </div>
        <div className="w-full max-w-lg p-10 relative z-10 flex flex-col justify-center bg-neutral-900/80 backdrop-blur-sm">
          <div className="w-full max-w-xs m-auto">
            <div className="divide-y divide-neutral-200/20 space-y-10">
              <div className="relative">
                <h1 className="text-2xl font-semibold text-center text-white mb-2">
                  Signin
                </h1>
                <div className="text-sm text-slate-300 text-center font-thin">
                  Please use your credentials to signin
                </div>
              </div>
              <form className="pt-4 space-y-6">
                <div className="relative">
                  <Input
                    label={""}
                    labelClasses={""}
                    inputType={"email"}
                    inputPlaceholder={"Email address"}
                    inputClasses={"!text-white/80 !font-light !bg-neutral-900/50 !border-neutral-600 focus:!border-primary-500 !h-12 transition-all duration-200"}
                    inputValue={email}
                    setInput={setEmail}
                    functions={onSubmit}
                  />
                </div>
                <div className="relative">
                  <Input
                    label={""}
                    labelClasses={""}
                    inputType={"password"}
                    inputPlaceholder={"Your Password"}
                    inputClasses={"!text-white/80 !font-light !bg-neutral-900/50 !border-neutral-600 focus:!border-primary-500 !h-12 transition-all duration-200"}
                    inputValue={password}
                    setInput={setPassword}
                    functions={onSubmit}
                  />
                </div>
                
                <div className="relative flex justify-center">
                  <Button
                    buttonClasses="w-full !h-12"
                    buttonLabelClasses="uppercase"
                    buttonType={"button"}
                    buttonIcon={
                      isLogin
                        ? "fa-light fa-spinner fa-spin"
                        : "fa-light fa-long-arrow-right"
                    }
                    buttonIconPosition={"right"}
                    buttonLabel={"Signin"}
                    functions={onSubmit}
                    buttonHasLink={false}
                    buttonDisabled={isLogin}
                  />
                </div>
                <div className="relative flex items-center justify-center">
                  {/* <div className="">
                    <Link to={"/forgot-password"} className="text-primary-500 font-medium text-sm">Forgot Password</Link>
                  </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
