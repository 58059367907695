import { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { postData } from "../services/api";
import AssignMealPlanModal from "../components/modals/AssignMealPlanModal";
import toastr from "toastr";
import MealPlanPreviewModal from "../components/modals/mealPlanPreviewModal";
const MealplanCalander = () => {
  const [showModal, setShowModal] = useState(false);
  const [initialEvent, setInitialEvents] = useState([]);
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [mealTitle, setMealTitle] = useState("");
  const [itemDeleteId, setItemDeleteId] = useState("");
  const [editData,setEditData]=useState({});
  
  const mealList = async () => {
    const res = await postData(
      `assign-meal-list?limit=${9999}&offset=${0}`,
      {},
      "GET"
    );

    if (res.status === 200) {
      let obj = [];
      res.data.list.forEach((ele, i) => {
        obj[i] = {
          id: ele._id,
          title: ele.title,
          start: ele.date?.split("T")[0],
        };
      });

      setInitialEvents(obj);
    }
  };

  useEffect(() => {
    mealList();
  }, [showModal]);


  const handleDateSelect = (selectInfo) => {
    setShowModal(true);
    let calendarApi = selectInfo.view.calendar;
    setDate(selectInfo.startStr);
    calendarApi.unselect();
    if (mealTitle) {
      calendarApi.addEvent({
        title: mealTitle,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      });
    }
  };

  const handleEventClick = async(clickInfo) => {
   
    setItemDeleteId(clickInfo.event.id);

    const res=await postData(`assign-meal/details?id=${clickInfo.event.id}`,{
    
    },"GET")
    if(res.status===200){
      setEditData({...res.data,
      date:res.data?.date.split("T")[0]
      });
    }
    setShowModal(true)
  };

  const handelDragEvent = async (e) => {
    const details = await postData(
      `assign-meal/details?id=${e.event.id}`,
      {},
      "GET"
    );

    if (details.status === 200) {
      const res = await postData(
        `assign-meal/edit`,
        {
          id:details.data?._id || null,
          category_id: details.data?.category[0]?._id,
          recipe_id: details.data?.recipe[0]?._id,
          user: details.data?.user[0]?._id,
          date: e.event?.startStr,
          title: details.data?.title,
        },
        "PUT"
      );

      if (res.status === 200) {
        toastr.success(res.message);
      } else {
        toastr.error(res.message);
      }
    }
  };

  return (
    <>
      {initialEvent.length > 0 && (
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
          initialView="dayGridMonth"
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          initialEvents={initialEvent}
          select={handleDateSelect}
          eventClick={handleEventClick}
          eventChange={handelDragEvent}
        />
      )}
      {initialEvent.length <= 0 && (
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
          initialView="dayGridMonth"
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          initialEvents={initialEvent}
          select={handleDateSelect}
          eventClick={handleEventClick}
        />
      )}
      {
        Object.keys(editData).length > 0 ?  <MealPlanPreviewModal
        show={showModal}
        setShow={setShowModal}
        title={ "Preview"}
        mealTitle={mealTitle}
        setMealTitle={setMealTitle}
        date={date}
        setDate={setDate}
        editData={editData}
        setEditData={setEditData}
        setInitialEvents={setInitialEvents}
        itemDeleteId={itemDeleteId}
        calanderView={true}
      /> 
      
        : <AssignMealPlanModal
          show={showModal}
          setShow={setShowModal}
          title={"Add Meal Plan"}
          mealTitle={mealTitle}
          setMealTitle={setMealTitle}
          date={date}
          setDate={setDate}
          editData={editData}
          setEditData={setEditData}
          setInitialEvents={setInitialEvents}
          itemDeleteId={itemDeleteId}
          calanderView={true}
        /> 

      }
       

      
    </>
  );
};

export default MealplanCalander;
