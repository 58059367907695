import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import DefaultSidebar from '../shared/DefaultSidebar';
import DefaultHeader from '../shared/DefaultHeader';
import DefaultFooter from "../shared/DefaultFooter";

const DefaultLayout = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className='relative flex w-full h-screen overflow-auto scroll-smooth scrollbar'>
      <div
        className={
          'fixed h-screen flex transition-all duration-100 z-10 ' +
          (isMenuOpen ? 'w-16' : 'w-60')
        }
      >
        <DefaultSidebar isMenuOpen={isMenuOpen} />
      </div>
      <div
        className={
          'relative w-full flex flex-col transition-all duration-100 ' +
          (isMenuOpen ? 'pl-16' : 'pl-60')
        }
      >
        <DefaultHeader isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        <div className='relative w-full p-6 bg-slate-100'>
          <div className="min-h-[calc(100vh-11rem)]">
            <Outlet />
          </div>
        </div>
        <DefaultFooter/>
      </div>
    </div>
  );
};

export default DefaultLayout;