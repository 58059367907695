import React from 'react';

const DefaultFooter = () => {
  return (
    <>
      <div className="min-h-[64px] z-50 w-full flex items-center px-6 bg-white shadow gap-4">
        <div className="text-sm text-slate-400 font-light">&copy; Copyright 2023 | All rights and reserved</div>
      </div>
    </>
  );
};

export default DefaultFooter;