import { useState, useEffect, useRef } from "react";
import Input from "../form/Input";
import AddEditLayoutModal from "../../containers/modals/AddEditLayoutModal";
import { postData } from "../../services/api";
import toastr from "toastr";
import { tinymceApiKey } from "../../helpers";
import { Editor } from "@tinymce/tinymce-react";

const AddEditEmailTemplateModal = ({
  title = "",
  show = false,
  editData = {},
  setEditData = () => {},
  setShow = () => {},
  setList = () => {},
  addType = "ASC",
}) => {
  const contentRef = useRef(null);
  const [disabled, setDisabled] = useState();
  const [subject, setSubject] = useState("");
  const [cc, setCC] = useState("");
  const [toEmail, setToEmail] = useState("");
  const [fromEmail, setFromEmail] = useState("");
  const [content, setContent] = useState("");
  const [code, setCode] = useState("");
  useEffect(() => {
    if (editData?._id) {
      setSubject(editData.subject);
      setFromEmail(editData.from_email);
      setToEmail(editData.to_email);
      setContent(editData.content);
      setCode(editData.code);
      setCC(editData.cc_email);
    }
  }, [editData]);

  const closeModal = () => {
    setShow(false);
    setDisabled(false);
    setEditData({});
    setSubject("");
    setFromEmail("");
    setToEmail("");
    setContent("");
    setCode("");
    setCC("");
  };

  const onSubmit = async () => {
    setDisabled(true);

    const res = await postData(
      `email-template/${editData?._id ? "edit" : "add"}`,
      {
        id: editData?._id || null,
        code,
        subject,
        content,
        from_email: fromEmail,
        to_email: toEmail,
        cc_email:cc,
      },
      editData?._id ? "PUT" : "POST"
    );

    if (res.status === 200 || res.status === 201) {
      if (!editData?._id) {
        if (addType !== "DESC") {
          setList((list) => ({
            ...list,
            data: [...list.data, res.data],
            count: list.count + 1,
          }));
        } else {
          setList((list) => ({
            ...list,
            data: [res.data, ...list.data],
            count: list.count + 1,
          }));
        }
      } else if (editData && editData?._id) {
        setList((list) => ({
          ...list,
          data: list.data.map((item) =>
            item._id !== editData._id ? item : res.data
          ),
        }));
      }

      closeModal();
      toastr.success(res.message);
    } else {
      setDisabled(false);
      toastr.error(res.message);
    }
  };

  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      setShow={setShow}
      onClose={closeModal}
      disabled={disabled}
      onSubmit={onSubmit}
      modalSize="m"
    >
      <div className="relative w-full">
        <div className="mb-4">
          <Input
            label={"Template Subject"}
            labelClasses={"!text-xs"}
            inputType={"text"}
            inputPlaceholder={"Code"}
            inputValue={code}
            setInput={() => {}}
          />
        </div>
        <div className="mb-4">
          <Input
            label={"Template Subject"}
            labelClasses={"!text-xs"}
            inputType={"text"}
            inputPlaceholder={"Enter Template Subject"}
            inputValue={subject}
            setInput={setSubject}
          />
        </div>

        <div className="mb-4">
          <Input
            label={"To"}
            labelClasses={"!text-xs"}
            inputType={"text"}
            inputPlaceholder={"Enter To Email"}
            inputValue={toEmail}
            setInput={setToEmail}
          />
        </div>
        <div className="mb-4">
          <Input
            label={"From"}
            labelClasses={"!text-xs"}
            inputType={"text"}
            inputPlaceholder={"Enter From Email"}
            inputValue={fromEmail}
            setInput={setFromEmail}
          />
        </div>

        <div className="mb-4">
          <Input
            label={"CC"}
            labelClasses={"!text-xs"}
            inputType={"text"}
            inputPlaceholder={"Enter CC"}
            inputValue={cc}
            setInput={setCC}
          />
        </div>

        <div className="mb-4">
          <Editor
            apiKey={tinymceApiKey()}
            onInit={(evt, editor) => (contentRef.current = editor)}
            value={content}
            init={{
              height: 300,
              menubar: false,
              plugins: [
                "code",
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "code | undo redo | formatselect | " +
                "bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help ",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
            onEditorChange={(newText) => setContent(newText)}
          />
        </div>
      </div>
    </AddEditLayoutModal>
  );
};

export default AddEditEmailTemplateModal;
