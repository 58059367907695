import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

import FreeAuthRoute from "./routes/FreeAuthRoute";
import ProtectedRoute from "./routes/ProtectedRoute";

import AuthLayout from "./layouts/AuthLayout";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import ForgotPassword from "./pages/ForgotPassword";
import DefaultLayout from "./layouts/DefaultLayout";
import Dashboard from "./pages/Dashboard";
import Settings from "./pages/Settings";
import AdminProfile from "./pages/AdminProfile";
//CMS
import EmailTemplates from "./pages/EmailTemplates";
import ManageEmailTemplate from "./pages/ManageEmailTemplate";

//CRM
import UserManage from "./pages/UserManage";
import Users from "./pages/Users";
import Admins from "./pages/Admins";
import Chefs from "./pages/Chefs";
import PasswordCreate from "./pages/PasswordCreate";
import Notification from "./pages/Notification";
import Categories from "./pages/Categories";
import SubCategories from "./pages/SubCategories";
import Ingredients from "./pages/Ingredients";
import Nutritions from "./pages/Nutritions";
import Recipe from "./pages/Recipe";
import AddEditCategory from "./pages/AddEditCategory";
import Subscriptions from "./pages/Subscriptions";
import MealPlans from "./pages/MealPlans";
import Classes from "./pages/Classes";
import Achievements from "./pages/Achievements";
import Groceries from "./pages/Groceries";
import Appliances from "./pages/Appliances";
import MealplanCalander from "./pages/MealPlanCalander";
import UserPreview from "./pages/UserPreview";
import MealTimes from "./pages/MealTimes";
import DefficultsRecipe from "./pages/DefficultsRecipe";

const CreateRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<AuthLayout />}>
            <Route
              path="/"
              exact
              element={
                <FreeAuthRoute>
                  <Login />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/signin" element={<AuthLayout />}>
            <Route
              path="/signin"
              exact
              element={
                <FreeAuthRoute>
                  <Login />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/signup" element={<AuthLayout />}>
            <Route
              path="/signup"
              exact
              element={
                <FreeAuthRoute>
                  <Signup />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/forgot-password" element={<AuthLayout />}>
            <Route
              path="/forgot-password"
              exact
              element={
                <FreeAuthRoute>
                  <ForgotPassword />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/manage" element={<AuthLayout />}>
            <Route
              path="/manage"
              exact
              element={
                <FreeAuthRoute>
                  <PasswordCreate />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/dashboard" element={<DefaultLayout />}>
            <Route
              path="/dashboard"
              exact
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/settings" element={<DefaultLayout />}>
            <Route
              path="/settings"
              exact
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* CMS Part  */}
          <Route path="/email-templates" element={<DefaultLayout />}>
            <Route
              path="/email-templates"
              exact
              element={
                <ProtectedRoute>
                  <EmailTemplates />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/edit-email/:id" element={<DefaultLayout />}>
            <Route
              path="/edit-email/:id"
              exact
              element={
                <ProtectedRoute>
                  <ManageEmailTemplate />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* CRM Part  */}
          <Route path="/user/add" element={<DefaultLayout />}>
            <Route
              path="/user/add"
              exact
              element={
                <ProtectedRoute>
                  <UserManage />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admins" element={<DefaultLayout />}>
            <Route
              path="/admins"
              exact
              element={
                <ProtectedRoute>
                  <Admins />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/achievements" element={<DefaultLayout />}>
            <Route
              path="/achievements"
              exact
              element={
                <ProtectedRoute>
                  <Achievements />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/classes" element={<DefaultLayout />}>
            <Route
              path="/classes"
              exact
              element={
                <ProtectedRoute>
                  <Classes />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/chefs" element={<DefaultLayout />}>
            <Route
              path="/chefs"
              exact
              element={
                <ProtectedRoute>
                  <Chefs />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="/users" element={<DefaultLayout />}>
            <Route
              path="/users"
              exact
              element={
                <ProtectedRoute>
                  <Users />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="/user/preview/:id" element={<DefaultLayout />}>
            <Route
              path="/user/preview/:id"
              exact
              element={
                <ProtectedRoute>
                  <UserPreview />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/categories/:for" element={<DefaultLayout />}>
            <Route
              path="/categories/:for"
              exact
              element={
                <ProtectedRoute>
                  <Categories />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="/groceries" element={<DefaultLayout />}>
            <Route
              path="/groceries"
              exact
              element={
                <ProtectedRoute>
                  <Groceries />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="/meal-plan-calander" element={<DefaultLayout />}>
            <Route
              path="/meal-plan-calander"
              exact
              element={
                <ProtectedRoute>
                  <MealplanCalander />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="/sub-categories" element={<DefaultLayout />}>
            <Route
              path="/sub-categories"
              exact
              element={
                <ProtectedRoute>
                  <SubCategories />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="/add-category" element={<DefaultLayout />}>
            <Route
              path="/add-category"
              exact
              element={
                <ProtectedRoute>
                  <AddEditCategory />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/edit-category/:id" element={<DefaultLayout />}>
            <Route
              path="/edit-category/:id"
              exact
              element={
                <ProtectedRoute>
                  <AddEditCategory />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="/ingredients" element={<DefaultLayout />}>
            <Route
              path="/ingredients"
              exact
              element={
                <ProtectedRoute>
                  <Ingredients />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="/nutritions" element={<DefaultLayout />}>
            <Route
              path="/nutritions"
              exact
              element={
                <ProtectedRoute>
                  <Nutritions />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="/recipe" element={<DefaultLayout />}>
            <Route
              path="/recipe"
              exact
              element={
                <ProtectedRoute>
                  <Recipe />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/mealtimes" element={<DefaultLayout />}>
            <Route
              path="/mealtimes"
              exact
              element={
                <ProtectedRoute>
                  <MealTimes />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/defficults" element={<DefaultLayout />}>
            <Route
              path="/defficults"
              exact
              element={
                <ProtectedRoute>
                  <DefficultsRecipe />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="/mealplans-list" element={<DefaultLayout />}>
            <Route
              path="/mealplans-list"
              exact
              element={
                <ProtectedRoute>
                  <MealPlans />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="/edit/user/:id" element={<DefaultLayout />}>
            <Route
              path="/edit/user/:id"
              exact
              element={
                <ProtectedRoute>
                  <UserManage />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/profile" element={<DefaultLayout />}>
            <Route
              path="/profile"
              exact
              element={
                <ProtectedRoute>
                  <AdminProfile />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/notification" element={<DefaultLayout />}>
            <Route path="/notification" exact element={<Notification />} />
          </Route>

          <Route path="/site-settings" element={<DefaultLayout />}>
            <Route path="/site-settings" exact element={<Settings />} />
          </Route>

          <Route path="/subscriptions" element={<DefaultLayout />}>
            <Route path="/subscriptions" exact element={<Subscriptions />} />
          </Route>

          <Route path="/appliances" element={<DefaultLayout />}>
            <Route
              path="/appliances"
              exact
              element={
                <ProtectedRoute>
                  <Appliances />
                </ProtectedRoute>
              }
            />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default CreateRoutes;
