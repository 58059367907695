import React from "react";
import DropdownCheckbox from "../../form/DropdownCheckbox";

function MultipalSearch({ data = [], updateHandler = () => {},label }) {
  return (
    <>
      {data.length > 0 && (
        <DropdownCheckbox
          data={data}
          functions={(id, event) => updateHandler(id, event)}
          label={label}
        />
      )}
    </>
  );
}


export default MultipalSearch;
