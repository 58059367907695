import { useState, useEffect } from "react";
import Input from "../form/Input";
import AddEditLayoutModal from "../../containers/modals/AddEditLayoutModal";
import { fileUpload, postData } from "../../services/api";
import toastr from "toastr";
import ButtonFile from "../form/ButtonFile";
import Image from "../elements/Image";
import Button from "../form/Button";

const AddEditCategoryModal = ({
  title = "",
  show = false,
  editData = {},
  setEditData = () => {},
  setShow = () => {},
  setList = () => {},
  addType = "ASC",
  urlParams = {},
}) => {
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState("");
  const [image, setImage] = useState();

  useEffect(() => {
    if (editData?._id) {
      setName(editData.name);
      setImage(editData?.image);
    } else {
      setName("");
      setImage("");
    }
  }, [editData]);

  const closeModal = () => {
    setShow(false);
    setName("");
    setEditData({});
    setImage("");
    setLoader(false)
  };


  const onImageChange = (e) => {
    setLoader(true);
    setImage(e.target.files[0]);
    const imageUploadFn = async () => {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      setLoader(false);
      // const imgRes = await fileUpload(
      //   "file-upload",formData,"POST"
      // );
      // if (imgRes.status === 200) {
      //   setImage(imgRes?.location);
      //   setLoader(false);
      // } else {
      //   setLoader(false);
      // }
    };

    imageUploadFn();
  };
 
  const onSubmit = async () => {
    let url = 'categories';
    if(editData?._id){
      url = `categories/${editData?._id}`
    }
    let formData = new FormData();
    formData.append('name',name);
    if(editData?._id){
      formData.append('id',editData?._id || null);
    }
   
    formData.append('type',1);
    formData.append('image',image);
    const res = await postData(url, formData,editData?._id?"PUT":"POST");

    if (res.status === 200 || res.status === 201) {
      setName("");
      if (!editData?._id) {
        if (addType !== "DESC") {
          setList((list) => ({
            ...list,
            data: [...list.data, res.data],
            count: list.count + 1,
          }));
        } else {
          setList((list) => ({
            ...list,
            data: [res.data, ...list.data],
            count: list.count + 1,
          }));
        }
      } else if (editData && editData?._id) {
        setList((list) => ({
          ...list,
          data: list.data.map((item) =>
            item._id !== editData._id ? item : res.data
          ),
        }));
      }

      closeModal();
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      setShow={setShow}
      onClose={closeModal}
      disabled={loader}
      onSubmit={onSubmit}
      modalSize="m"
    >
      <div className="w-full">
        <div className="mb-4">
          <Input
            label={"Theme Name"}
            labelClasses={"!text-xs"}
            inputType={"text"}
            inputPlaceholder={" Enter Theme Name"}
            inputValue={name}
            setInput={setName}
          />
        </div>
        {/* <div className="mb-4">
          <ButtonFile
            functions={onImageChange}
            buttonLabel="Upload Image"
            buttonIconPosition=""
            buttonClasses={"!bg-white !text-slate-400 border border-slate-200"}
          />
        </div> */}
        {/* {image ? (
          <>
            {image !== null && image instanceof File ? (
              <div className="relative w-full rounded border border-slate-200 flex overflow-hidden">
                <Button
                  buttonClasses={
                    "!absolute !z-10 !top-1 !right-1 !cursor-pointer !bg-white !text-slate-400 !w-6 !h-6 !rounded-full !shadow !text-sm"
                  }
                  buttonType={"button"}
                  buttonIcon={"fa-light fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonHasLink={false}
                  functions={() => setImage(null)}
                />
                <Image
                  src={URL.createObjectURL(image)}
                  alt={name}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={"object-cover"}
                />
              </div>
            ) : (
              <div className="relative w-full rounded border border-slate-200 flex overflow-hidden">
                <Button
                  buttonClasses={
                    "!absolute !z-10 !top-1 !right-1 !cursor-pointer !bg-white !text-slate-400 !w-6 !h-6 !rounded-full !shadow !text-sm"
                  }
                  buttonType={"button"}
                  buttonIcon={"fa-light fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonHasLink={false}
                  functions={() => setImage(null)}
                />
                <Image
                  src={image}
                  alt={name}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={"object-cover"}
                />
              </div>
            )}
          </>
        ) : (
          ""
        )} */}
      </div>
    </AddEditLayoutModal>
  );
};

export default AddEditCategoryModal;
