import React, { useEffect, useRef, useState } from "react";
import EditorJS from "@editorjs/editorjs";
import { Tools } from "./tools/Tools";

const TextEditor = ({
  width = "750px",
  height = "200px",
  value = { time: new Date().getTime(), blocks: [], version: "2.28.0" },
  name = "editor",
  onChange = () => {},
}) => {
  const ejInstance = useRef();
  const editorRef = useRef(null);
  const [valueData, setValueData] = useState(
    value || { time: new Date().getTime(), blocks: [], version: "2.28.0" }
  );

  const initEditor = async () => {
    const editor = new EditorJS({
      holder: editorRef.current,
      onReady: async () => {
        ejInstance.current = editor;
      },
      autofocus: true,
      onChange: async () => {
        let content = await editor.saver.save();
        onChange({ target: { name, value: content } });
      },
      tools: Tools,
      data: {
        blocks: [...valueData?.blocks], // Pass the default content as an array of blocks
      },
    });
  };

  // Tools.image.config.endpoints[`byFile`] = "abcd";
  // Tools.image.config.endpoints[`byUrl`] = "abcd";

  useEffect(() => {
    if (ejInstance.current === null) {
      initEditor();
    }

    return () => {
      ejInstance?.current?.destroy();
      ejInstance.current = null;
    };
  }, [editorRef.current]);

  return (
    <div className="overflow-hidden border-2 border-gray-100 rounded-md p-2">
      <div
        className={`text-editor-sec overflow-y-scroll relative`}
        style={{ height, width }}
      >
        <div
          ref={editorRef}
          className="w-full mx-auto absolute top-0 left-0"
        ></div>
      </div>
    </div>
  );
};

export default TextEditor;
