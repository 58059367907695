import { useEffect } from 'react';
import CreateRoutes from './routes';
import { postData } from './services/api';

function App() {
  useEffect(() => {
     const loadFavIcon=async()=>{
      const res = await postData("site-setting", {}, "GET");

      if(res.status===200){
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href =res.data.favicon;
      }
     }

     loadFavIcon()
  }, []);
  return (
    <CreateRoutes />
  );
}

export default App;
