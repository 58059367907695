import axios from "axios";
import BASE_URL from "../config/host";

export const postData = async (url, data, method) => {
  try {
    const res = await axios({
      url: BASE_URL+url,
      method,
      data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        //"Content-Type":"multipart/form-data"
      },
    });
    return  res.data;
  } catch (error) {
    if(error.name==="AxiosError"){
      return { status: 400, message: error.response?.data.message };
    }
    return { status: 400, message: error.message };
  }
};

export const fileUpload = async (url, data, method) => {
  try {
    const res = await axios({
      url: BASE_URL+url,
      method,
      data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        "Content-Type":"multipart/form-data"
      },
    });
    return  res.data;
  } catch (error) {
    return { status: 400, message: error.message };
  }
};

