import ModalContainer from '../ModalContainer';
import Button from '../../components/form/Button';

const AddEditLayoutModal = ({
  show = false,
  disabled = false,
  title = '',
  onClose = () => {},
  onSubmit = () => {},
  modalSize = '',
  children,
  isBtn=true,
  hasAction,
  actionData
}) => {
  return (
    <ModalContainer show={show} title={title} closeModal={onClose} modalSize={modalSize} hasAction={hasAction} actionData={actionData}>
      <div className='relative'>
        <div className='mb-4 flex flex-col'>
          { children }
        </div>
        { isBtn &&
        <div className='pt-3 px-6 -mx-6 border-t border-slate-200'>
          <div className='flex items-center justify-center gap-4'>
             <Button
              buttonClasses='!w-full'
              buttonLabelClasses=''
              buttonType={'button'}
              buttonIcon={
                disabled
                  ? 'fa-light fa-spinner fa-spin'
                  : 'fa-light fa-check'
              }
              buttonIconPosition={'left'}
              buttonLabel={'Submit'}
              functions={onSubmit}
              buttonHasLink={false}
              buttonDisabled={disabled}
            />
            
          </div>
        </div>
        }
      </div>
    </ModalContainer>
  );
};

export default AddEditLayoutModal;
