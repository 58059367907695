import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TableAlt from "../components/elements/table/TableAlt";
import ListLayout from "../containers/ListLayout";
import { postData } from "../services/api";
import toastr from "toastr";
import AddEditNutritionModal from "../components/modals/AddEditNutritionModal";

const Nutritions = () => {
  const user = useSelector((state) => state.auth.user);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [list, setList] = useState({
    loading: true,
    data: [],
    count: 0,
  });
  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);
  const [showModal, setShowModal] = useState(false)
  const [editData, setEditData] = useState({})
  

  useEffect(() => {
    if (user?.email) {

      const loadData = async () => {
        setList((list) => ({ ...list, loading: true }));
        const searchParams = {};
        if (keyword) {
          searchParams.title = { $regex: keyword, $options: "i" };
        }
        
        const res = await postData(`nutrition/list?keyword=${keyword}&keyword_key=title&limit=${limit}&offset=${offset}`, {
          keyword,
          keyword_key:'title',
          limit: limit,
          offset: offset
        },"GET");
        if (res.status === 200) {
          setList((list) => ({
            ...list,
            data: res.data.list,
            loading: false,
            count: res.data.count,
          }));
        } else {
          setList((list) => ({ ...list, loading: false }));
        }
      };

      loadData();
    }
  }, [user, keyword, limit, offset, sortField, sortType]);

  const tableHeadData = [
    {
      _id: 1,
      width: 15,
      title: "Name",
      name: "title",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 2,
      width: 15,
      title: "Status",
      name: "status",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 3,
      width: 15,
      title: "Created At",
      name: "created_at",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 4,
      title: "Action",
      action: true,
    },
  ];

  const changeStatus = async (id) => {
    const res = await postData("crud/statusChange", {
      table_name: "nutrition",
      id: [id],
      column_name: "status",
      column_value: !(
        list.data.find((item) => item._id === id).status || false
      ),
    },"POST");

    if (res.status === 200) {
      setList((list) => ({
        ...list,
        data: list.data.map((item) =>
          item._id !== id
            ? item
            : {
                ...item,
                status: !(
                  list.data.find((item) => item._id === id).status || false
                ),
              }
        ),
      }));
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  const actionData = (item) => [
    {
      name: "Edit",
      icon: "fa-pen-to-square",
      type: "button",
      onClick: (id) => {
        setShowModal(true);
        setEditData(list.data.find((item) => item._id === id) || {});
      },
    },
    {
      name: item.status ? "Inactive" : "Active",
      icon: item.status ? "fa-circle-exclamation" : "fa-circle-check",
      type: "button",
      onClick: changeStatus,
    },
    {
      name: 'Delete',
      icon: 'fa-trash-can',
      type: 'button',
      actionType: 'delete',
      deleteLink: `nutrition/delete/${item._id}`,
      standout:true
    },
  ];

  const onDrag = async (data = []) => {
    const res = await postData("crud/ordering", {
      table_name: "nutrition",
      ordering_data: data.map((item, i) => ({ id: item._id, ordering: i })),
    },"POST");

    if (res.status !== 200) {
      toastr.error(res.message);
    }
  };

  return (
    <>
      <ListLayout
        title="Nutritions"
        addName="Add Nutrition"
        onAdd={() => setShowModal(true)}
        limit={limit}
        setLimit={setLimit}
        keyword={keyword}
        setKeyword={setKeyword}
        noOfPage={Math.ceil(list.count / limit)}
        onPageChange={(val) => setOffset(val.selected * limit)}
      >
        <TableAlt
          tableHeadData={tableHeadData}
          tableData={list.data}
          loading={list.loading}
          sortField={sortField}
          sortType={sortType}
          setSortField={setSortField}
          setSortType={setSortType}
          actionData={actionData}
          setList={setList}
          isDraggable={true}
          onDrag={onDrag}
        />
      </ListLayout>
      <AddEditNutritionModal
        show={showModal}
        setShow={setShowModal}
        title={
          Object.keys(editData).length > 0 ? "Edit Nutrition" : "Add Nutrition"
        }
        list={list.data}
        setList={setList}
        editData={editData}
        setEditData={setEditData}
      />
    </>
  );
};

export default Nutritions;
